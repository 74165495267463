import Page from "./access-page";
import { PageContextProvider } from "../role/context";

const Component = () => {
  return (
    <PageContextProvider>
      <Page />
    </PageContextProvider>
  );
};

export default Component;
