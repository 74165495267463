import { useEffect, useState } from "react";
import { useCompany } from "../../../../hooks/company";
import { useSession } from "../../../../hooks/session";
import List, { ListItemButton, ListItemText } from "../../../list";
import { useTheme } from "@mui/material/styles";
import Avatar from "../../../avatar/avatar";
import {
  ApartmentOutlined as ApartmentOutlinedIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import Typography from "../../../typography";

const SwapCompany = ({ closeDialog }) => {
  const theme = useTheme();
  const [companies, setCompanies] = useState();
  const session = useSession();
  const hooks = {
    company: useCompany(),
  };

  const selectCompany = async (companyId) => {
    await session.selectCompany(companyId);
    closeDialog();
  };

  useEffect(() => {
    (async () => {
      await hooks.company.fetchCompanies(session).then((res) => {
        setCompanies(res.items);
      });
    })();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Avatar
        sx={{
          margin: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <ApartmentOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Selecione a empresa
      </Typography>
      <List
        sx={{
          marginTop: theme.spacing(1),
          padding: 0,
          width: "100%",
          border: "1px solid #dee2e6",
          backgroundColor: "1px solid #dee2e6",
        }}
      >
        {companies?.map((company, index) => {
          return (
            <ListItemButton
              key={`company-item-${index}`}
              selected={company.id === session.account.selectedCompany.id}
              sx={{
                borderBottom: "1px solid #dee2e6",
                borderCollapse: "collapse",
              }}
              onClick={() => {
                selectCompany(company.id);
              }}
            >
              <ListItemText>{company.name}</ListItemText>
              {company.id === session.account.selectedCompany.id && (
                <CheckIcon />
              )}
            </ListItemButton>
          );
        })}
      </List>
    </div>
  );
};

export default SwapCompany;
