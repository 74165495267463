import { Grid } from "@mui/material";
import Page from "../../../../components/page";
import { usePageContext } from "./location-detail-page.ctx";

function LocationDetailPage() {
  const page = usePageContext();
  return (
    <Page showAppBar showSideBar requiresAuthentication>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>Localização</h1>
        </Grid>
        <Grid item xs={12}>
          param id: {page.params.id}
        </Grid>
      </Grid>
    </Page>
  );
}

export default LocationDetailPage;
