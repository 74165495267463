import { createContext, useCallback, useContext, useState } from "react";
import { App } from "./app-hook.types";

const AppContext = createContext<any>({});

const useApp = () => {
  const [state, setState] = useContext(AppContext);

  const app: App = state;

  const setApp = useCallback(
    (
      props: App = {
        sidebar: {},
      }
    ) => {
      setState((current: any) => ({ ...current, ...props }));
    },
    []
  );

  const collapseSidebar = useCallback(() => {
    const newValue = {
      ...app.sidebar,
      isCollapsed: true,
    };
    setApp({
      sidebar: newValue,
    });
    localStorage.setItem("sidebar", JSON.stringify(newValue));
  }, []);

  const openSidebar = useCallback(() => {
    const newValue = {
      ...app.sidebar,
      isCollapsed: false,
    };
    setApp({
      sidebar: newValue,
    });
    localStorage.setItem("sidebar", JSON.stringify(newValue));
  }, []);

  return {
    sidebar: {
      ...app.sidebar,
      collapse: collapseSidebar,
      open: openSidebar,
    },
  };
};

const AppContextProvider = ({ children }: { children: JSX.Element }) => {
  const optionsFromLocalStorageStr = localStorage.getItem("sidebar");
  const optionsFromLocalStorage: any = optionsFromLocalStorageStr
    ? JSON.parse(optionsFromLocalStorageStr)
    : null;
  if (!optionsFromLocalStorage)
    localStorage.setItem(
      "sidebar",
      JSON.stringify({
        isCollapsed: false,
      })
    );

  const [state, setState] = useState({
    sidebar: {
      isCollapsed: optionsFromLocalStorage?.isCollapsed || false,
    },
  });
  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};

export { useApp, AppContextProvider };
