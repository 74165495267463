import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface Data {
  user?: User;
}

export interface Values {
  name?: PageField;
  hasChange?: PageField;
  isLoading?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {},
  {
    name: createPageField("", { required: true }),
    hasChange: createPageField(false, { required: true }),
    isLoading: createPageField(false, { required: true }),
  }
);

export { PageContextProvider, usePageContext };
