import { Checkbox } from "@mui/material";

import { ListItemText, ListItemButton } from "../../../components/list";
import { useState } from "react";
import Grid from "../../../components/grid";

export function AccessListItem({ access }) {
  const [checked, setChecked] = useState();
  return (
    <Grid item xs={6} md={3} lg={2}>
      <ListItemButton
        sx={{
          padding: "0 15px",
          border: "1px solid #dee2e6",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
        onClick={() => {
          let check = !access.roleAccess;
          setChecked(check);
          access.roleAccess = check;
        }}
      >
        <Checkbox checked={access.roleAccess} />

        <ListItemText>{access.name}</ListItemText>
      </ListItemButton>
    </Grid>
  );
}
