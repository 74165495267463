import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  AutocompleteProps as MuiAutoCompleteProps,
} from "@mui/material";
// import { Clear } from "@mui/icons-material";
import { AutocompleteProps } from "./auto-complete.types";

function Autocomplete(
  props: AutocompleteProps<any, boolean, boolean, boolean>
) {
  const muiAutocompleteProps: MuiAutoCompleteProps<
    any,
    boolean,
    boolean,
    boolean
  > = {
    // clearIcon: Clear,
    clearText: "Clear",
    closeText: "Close",
    disabled: false,
    forcePopupIcon: "auto",
    fullWidth: false,
    openText: "Open",
    readOnly: false,
    size: "medium",
    options: [],
    isOptionEqualToValue: (option, value) => {
      return option.id === value.id;
    },
    renderInput: (params) => <MuiTextField {...params} label={props.label} />,
    value: props.multiple ? [] : props.field?.value,
  };

  if (props.clearIcon) muiAutocompleteProps.clearIcon = props.clearIcon;

  if (props.clearText) muiAutocompleteProps.clearText = props.clearText;

  if (props.closeText) muiAutocompleteProps.closeText = props.closeText;

  if (props.disabled) muiAutocompleteProps.disabled = props.disabled;

  if (props.forcePopupIcon)
    muiAutocompleteProps.forcePopupIcon = props.forcePopupIcon;

  if (props.fullWidth) muiAutocompleteProps.fullWidth = props.fullWidth;

  if (props.openText) muiAutocompleteProps.openText = props.openText;

  if (props.noOptionsText)
    muiAutocompleteProps.noOptionsText = props.noOptionsText;

  if (props.readOnly) muiAutocompleteProps.readOnly = props.readOnly;

  if (props.size) muiAutocompleteProps.size = props.size;

  if (props.sx) muiAutocompleteProps.sx = props.sx;

  if (props.multiple) muiAutocompleteProps.multiple = props.multiple;

  if (props.loading) muiAutocompleteProps.loading = props.loading;

  if (props.loadingText) muiAutocompleteProps.loadingText = props.loadingText;

  if (props.disablePortal)
    muiAutocompleteProps.disablePortal = props.disablePortal;

  if (props.id) muiAutocompleteProps.id = props.id;

  if (props.renderInput) muiAutocompleteProps.renderInput = props.renderInput;

  if (props.renderOption)
    muiAutocompleteProps.renderOption = props.renderOption;

  if (props.onChange) muiAutocompleteProps.onChange = props.onChange;

  if (props.isOptionEqualToValue)
    muiAutocompleteProps.isOptionEqualToValue = props.isOptionEqualToValue;

  if (props.getOptionLabel)
    muiAutocompleteProps.getOptionLabel = props.getOptionLabel;

  if (props.options) muiAutocompleteProps.options = props.options;

  // if (props.options && props.field?.value) {
  //   const itemsFiltrados = props.options.filter((value, index, array) => {
  //     const valExist = muiAutocompleteProps.value.every((val: any) => {
  //       return val === value;
  //     });

  //     return valExist;
  //   });

  //   console.log(itemsFiltrados);
  // }

  if (props.field?.value) muiAutocompleteProps.value = props.field.value;

  return <MuiAutocomplete {...muiAutocompleteProps} />;
}

export default Autocomplete;
