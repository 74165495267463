import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from "@mui/material";
import { TypographyProps } from "./typography.types";

function Typography(props: TypographyProps) {
  const muiTypographyProps: MuiTypographyProps = {
    variant: "body1",
  };

  if (props.variant) muiTypographyProps.variant = props.variant;

  if (props.sx) muiTypographyProps.sx = props.sx;

  return (
    <MuiTypography component={props.component} {...muiTypographyProps}>
      {props.children}
    </MuiTypography>
  );
}

export default Typography;
