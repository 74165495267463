import { createPageContext } from "../../../components/page";

export interface Genre {
  id: number;
  description: string;
}

export interface Data {
  genres?: Genre[];
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {},
  {}
);

export { PageContextProvider, usePageContext };
