import { East } from "@mui/icons-material";
import { CircularProgress, Paper } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AutoComplete from "../../../components/auto-complete";
import Button from "../../../components/button";
import Dialog from "../../../components/dialog";
import Grid from "../../../components/grid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import Typography from "../../../components/typography";
import { useAsset } from "../../../hooks/asset";
import { useLocation } from "../../../hooks/location";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";

export default function AssetsFormPage() {
  const page = usePageContext();
  const navigate = useNavigate();
  const hooks = { local: useLocation(), asset: useAsset() };
  const session = useSession();
  const [queryParams, setQueryParams] = useSearchParams();
  const title = queryParams.get("id") ? "Editar Ativo" : "Novo Ativo";

  useEffect(() => {
    clearForm();
    if (!page.data.locals) (async () => await getLocals())();
    if (!page.data.genres) (async () => await getGenres())();
    if (queryParams.get("id"))
      (async () => await getFieldsData(queryParams.get("id")))();
  }, []);

  useEffect(() => {
    const pageValues = Object.values(page.values);
    page.setData({
      hasChange: pageValues.some((pageField) => {
        return pageField.value !== "" && pageField.value !== null;
      }),
    });
  }, [page.values]);

  const getFieldsData = async (id) => {
    const { asset, location } = await hooks.asset.fetchSingleAsset(session, {
      id,
    });

    page.setData({
      asset: { ...asset, location },
    });
    page.setValues({
      acquireAmount: {
        value: asset.acquireAmount,
      },
      description: {
        value: asset.description,
      },
      invoiceDate: {
        value: moment(asset.invoiceDate).format("yyyy-MM-DD"),
      },
      invoiceNumber: {
        value: asset.invoiceNumber,
        required: false,
      },
      local: {
        value: location,
        required: false,
      },
      observation: {
        value: asset.observation,
      },
      number: {
        value: asset.number,
      },
      yearsToDepreciate: { value: asset.yearsToDepreciate, required: false },
      futureSellValue: { value: asset.futureSellAmount, required: false },
      rfid: {
        value: asset.rfid,
      },
      selectedGenreId: {
        value: asset.genre,
      },
    });
  };

  const getLocals = async () => {
    const allLocals = await hooks.local.fetchLocations(session);

    const children = allLocals.filter((local, index, array) =>
      array.every((arrayItem) => arrayItem.parentId !== local.id)
    );

    page.setData({
      locals: children.map((child) => ({
        id: child.id,
        name: child.description,
      })),
    });
  };

  const getGenres = async () => {
    const genres = await hooks.asset.fetchGenresList(session);
    page.setData({
      genres,
    });
  };

  const clearForm = () => {
    page.setValues({
      local: { value: null },
      yearsToDepreciate: { value: null },
      futureSellValue: { value: null },
      description: { value: null },
      rfid: { value: null },
      number: { value: null },
      acquireAmount: { value: null },
      invoiceDate: { value: null },
      invoiceNumber: { value: null },
      observation: { value: null },
      startDate: { value: null },
      selectedGenreId: { value: null },
    });
    page.setData({
      asset: null,
    });
  };

  const moveAsset = async () => {
    await hooks.asset.moveAsset(
      session,
      queryParams.get("id"),
      page.data.movement.newValue.id
    );
  };

  const createOcurrence = async () => {
    await hooks.asset.newOcurrence(
      {
        futureSellAmount: page.values.futureSellValue.value,
        yearsToDepreciate: page.values.yearsToDepreciate.value,
        startDate: page.values.startDate.value || Date.now(),
      },
      queryParams.get("id"),
      session
    );
  };

  const saveEdit = async () => {
    page.setData({
      isLoading: true,
    });
    try {
      const response = await hooks.asset.updateAsset(session, {
        id: queryParams.get("id"),
        acquireAmount: page.values.acquireAmount.value,
        description: page.values.description.value,
        invoiceDate: page.values.invoiceDate.value,
        invoiceNumber: page.values.invoiceNumber.value,
        number: page.values.number.value,
        observation: page.values.observation.value,
        rfid: page.values.rfid.value,
        genreId: page.values.selectedGenreId.value.id,
      });
      if (response.errors)
        return page.notify("error", response.errors[0].message);

      return page.notify("success", "Ativo Atualizado com sucesso!");
    } catch (error) {
      page.notify("error", `${error}`);
    } finally {
      page.setData({
        isLoading: false,
      });
    }
  };

  const saveNewAsset = async () => {
    page.setData({
      isLoading: true,
    });
    try {
      const response = await hooks.asset.createAsset(session, {
        acquireAmount: page.values.acquireAmount.value,
        description: page.values.description.value,
        futureSellValue: page.values.futureSellValue.value,
        invoiceDate: page.values.invoiceDate.value,
        invoiceNumber: page.values.invoiceNumber.value,
        location: page.values.local.value.id,
        number: page.values.number.value,
        observation: page.values.observation.value,
        rfid: page.values.rfid.value,
        yearsToDepreciate: page.values.yearsToDepreciate.value,
        genreId: page.values.selectedGenreId.value.id,
      });
      if (response.errors)
        return page.notify("error", response.errors[0].message);

      return page.notify("success", "Ativo criado com sucesso!", clearForm());
    } catch (error) {
      page.notify("error", `${error}`);
    } finally {
      page.setData({
        isLoading: false,
      });
    }
  };

  return (
    <Page
      showAppBar
      showSideBar
      requiresAuthentication
      context={page.context}
      title={title}
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Ativos", link: "." },
        { description: title, link: "./form" },
      ]}
      toolbar={[
        <Dialog
          disableEscapeKeyDown={false}
          open={page.data.dialog.isOpen}
          title="Confirmar alterações?"
          actions={
            <Grid
              container
              sx={{ justifyContent: "space-between", px: 1, pb: 1 }}
            >
              <Grid item>
                <Button
                  type="button"
                  variant="text"
                  color="warning"
                  onClick={() => {
                    if (page.data.ocurrence.length > 0)
                      page.setData({ ocurrence: [] });

                    page.setData({
                      dialog: { isOpen: false },
                    });
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  onClick={async () => {
                    await saveEdit();
                    if (page.data.movement) await moveAsset();
                    if (page.data.ocurrence.length !== 0)
                      await createOcurrence();

                    page.setData({
                      dialog: { isOpen: false },
                      ocurrence: [],
                      movement: null,
                    });
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Grid container direction={"column"} spacing={3}>
            {page.data.movement && (
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    As alterações nesse ativo vão gerar uma nova movimentação:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "space-around", display: "flex" }}
                >
                  <strong>{page.data.movement.oldValue.name}</strong> <East />
                  <strong>{page.data.movement.newValue.name}</strong>
                </Grid>
              </Grid>
            )}
            {page.data.ocurrence.length !== 0 && (
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    As alterações nesse ativo vão gerar uma nova ocorrência:
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Grid item>Válido a partir de:</Grid>
                  <Grid item>
                    <TextField
                      variant={"outlined"}
                      fullWidth
                      size={"small"}
                      type="date"
                      field={page.values.startDate}
                      onChange={(event) =>
                        page.setValues({
                          startDate: { value: event.target.value },
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                {page.data.ocurrence.map((ocurrence) => (
                  <Grid key={ocurrence.field} item container spacing={1}>
                    <Grid item xs={12}>
                      <Typography>{ocurrence.field}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ justifyContent: "space-around", display: "flex" }}
                    >
                      <b>{ocurrence.oldValue}</b> <East />
                      <b>{ocurrence.newValue}</b>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Dialog>,
      ]}
    >
      {!page.data.locals || (queryParams.get("id") && !page.data.asset) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            if (!page.data.asset) {
              await saveNewAsset();
              return;
            }

            if (
              page.data.asset.yearsToDepreciate !==
              page.values.yearsToDepreciate.value
            ) {
              page.data.ocurrence.push({
                field: "Anos para depreciação",
                oldValue: page.data.asset.yearsToDepreciate,
                newValue: page.values.yearsToDepreciate.value,
              });
            }

            if (
              page.data.asset.futureSellAmount !==
              page.values.futureSellValue.value
            ) {
              page.data.ocurrence.push({
                field: "Valor futuro de venda",
                oldValue: page.data.asset.futureSellValue,
                newValue: page.values.futureSellValue.value,
              });
            }

            if (page.data.ocurrence.length !== 0 || page.data.movement)
              return page.setData({ dialog: { isOpen: true } });

            if (queryParams.get("id")) return saveEdit();
          }}
        >
          <Paper
            sx={{
              margin: "auto",
              maxWidth: 900,
              p: 3,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component={"h2"} variant={"h5"}>
                  Dados cadastrais
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={"Descrição"}
                  field={page.values.description}
                  placeholder={"Faça uma breve descrição do ativo"}
                  fullWidth
                  onChange={(event) =>
                    page.setValues({
                      description: {
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  field={page.values.rfid}
                  label="Tag RFID"
                  // disabled
                  placeholder="Número da TAG RFID lida pelo leitor"
                  fullWidth
                  onChange={(event) =>
                    page.setValues({
                      rfid: {
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  field={page.values.number}
                  label="Nº do Patrimônio"
                  placeholder="Código interno da empresa para controle dos bens"
                  fullWidth
                  onChange={(event) =>
                    page.setValues({
                      number: {
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutoComplete
                  id="local"
                  label="Local atual"
                  field={page.values.local}
                  options={page.data.locals ?? []}
                  getOptionLabel={(option) => {
                    return option.name || "";
                  }}
                  onChange={(_, val) => {
                    page.setValues({
                      local: {
                        value: val,
                      },
                    });
                    if (page.data.asset)
                      page.setData({
                        movement: {
                          oldValue: page.data.asset.location,
                          newValue: val,
                        },
                      });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  field={page.values.acquireAmount}
                  label="Valor Aquisição"
                  placeholder="Valor pago na aquisição do ativo"
                  disabled={
                    queryParams.get("id") !== null &&
                    page.data.asset.acquireAmount != 0
                  }
                  fullWidth
                  type={"number"}
                  onChange={(event) =>
                    page.setValues({
                      acquireAmount: {
                        value: event.target.valueAsNumber,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  field={page.values.yearsToDepreciate}
                  label="Anos para depreciação"
                  placeholder="Tempo total em anos para depreciação do ativo"
                  type={"number"}
                  fullWidth
                  onChange={(event) => {
                    page.setValues({
                      yearsToDepreciate: {
                        value: event.target.valueAsNumber,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  field={page.values.futureSellValue}
                  label="Valor futuro de venda"
                  placeholder="Valor futuro de venda após depreciação"
                  fullWidth
                  type={"text"}
                  onChange={(event) =>
                    page.setValues({
                      futureSellValue: {
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component={"h2"} variant={"h5"}>
                  Dados do faturamento
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  field={page.values.invoiceNumber}
                  label="Documento"
                  placeholder="Nota fiscal ou certidão de imóvel"
                  fullWidth
                  onChange={(event) =>
                    page.setValues({
                      invoiceNumber: {
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  field={page.values.invoiceDate}
                  label="Data de aquisição"
                  type={"date"}
                  placeholder="Quando o ativo foi adquirido"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    page.setValues({
                      invoiceDate: {
                        value: event.target.value,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoComplete
                  id="genre"
                  label="Gênero Fiscal"
                  field={page.values.selectedGenreId}
                  options={page.data.genres}
                  getOptionLabel={(option) => {
                    return (
                      `${option.description} - ${option.percentage}%` || ""
                    );
                  }}
                  onChange={(_, val) => {
                    page.setValues({
                      selectedGenreId: {
                        value: val,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  fullWidth
                  field={page.values.observation}
                  label="Observação"
                  rows={3}
                  placeholder="Observações adicionais sobre o ativo. Ex: Se está em boas condições, comprado novo, etc..."
                  onChange={(event) =>
                    page.setValues({
                      observation: {
                        value: event.target.value,
                      },
                    })
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{ justifyContent: "right" }}
              >
                <Grid item>
                  <Button
                    color="warning"
                    variant="text"
                    onClick={() => {
                      if (page.data.hasChange) {
                        clearForm();
                        setQueryParams("");
                        return;
                      }
                      navigate("/assets");
                    }}
                  >
                    {page.data.hasChange ? "Limpar" : "Cancelar"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" disabled={page.data.isLoading}>
                    {page.data.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Salvar"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </form>
      )}
    </Page>
  );
}
