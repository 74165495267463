import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TableViewIcon from "@mui/icons-material/TableView";
import { Calculate as CalculateIcon } from "@mui/icons-material";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton component="a" href="/">
      <ListItemIcon>
        <HomeIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>
    <ListItemButton component="a" href="/assets">
      <ListItemIcon>
        <TableViewIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Ativos" />
    </ListItemButton>
    <ListItemButton component="a" href="/calc">
      <ListItemIcon>
        <CalculateIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Cálculos" />
    </ListItemButton>
    <ListItemButton component="a" href="/settings">
      <ListItemIcon>
        <SettingsIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Configurações" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
