import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { ButtonProps } from "./button.types";

function Button(props: ButtonProps) {
  const muiButtonProps: MuiButtonProps = {
    fullWidth: false,
    variant: "contained",
    color: "primary",
  };

  if (props.sx) muiButtonProps.sx = {};

  if (props.type) muiButtonProps.type = props.type;

  if (props.fullWidth) muiButtonProps.fullWidth = props.fullWidth;

  if (props.variant) muiButtonProps.variant = props.variant;

  if (props.color) muiButtonProps.color = props.color;

  if (props.disabled) muiButtonProps.disabled = props.disabled;

  if (props.sx)
    muiButtonProps.sx = {
      ...props.sx,
    };

  if (props.onClick) muiButtonProps.onClick = props.onClick;

  if (props.startIcon) muiButtonProps.startIcon = props.startIcon;

  if (props.endIcon) muiButtonProps.endIcon = props.endIcon;

  return <MuiButton {...muiButtonProps}>{props.children}</MuiButton>;
}

export default Button;
