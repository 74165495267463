import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";

export interface User {
  id: string;
  name: string;
  email: string;
  admin: boolean;
  active: boolean;
}

export interface Role {
  id: string;
  name: string;
}

export interface Location {
  id?: string;
  name: string;
}

export interface Invite {
  email: string;
  roles: Role[];
  locations: Location[];
}

export interface Data {
  invite?: Invite;
  users?: User[];
  filteredUsers?: User[];
}

export interface Values {
  email?: PageField;
  roles?: PageField;
  locations?: PageField;
  openDialog?: PageField;
  searchInput?: PageField;
  pagination?: PageField;
  isLoading?: PageField;
  rows?: PageField;
  filteredUsers?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {},
  {
    email: createPageField("", { required: true }),
    roles: createPageField("", { required: true }),
    locations: createPageField("", { required: true }),
    openDialog: createPageField(false, { required: true }),
    searchInput: createPageField("", { required: true }),
    pagination: createPageField(undefined, { required: true }),
    isLoading: createPageField(false, { required: true }),
    rows: createPageField(undefined, { required: true }),
    filteredUsers: createPageField(undefined, { required: true }),
  }
);

export { PageContextProvider, usePageContext };
