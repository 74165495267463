import { createPageContext } from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";

export interface CalculationData {
  month: string;
  percentInThidMonth: number;
}

export interface AssetWithCalculation {
  accountingValue: number;
  acquirementDate: Date;
  acquirementValue: number;
  calcFisc: CalculationData[];
  calcCont: CalculationData[];
  depreciation: number;
  description: string;
  depreciationPercentageAtAccountingDate: number;
  depreciationValueAtAccountingDate: number;
  despicableValue: number;
  id: string;
  sellValue: number;
  yearsToDepreciate: number;
}

export interface Data {
  calculatedAssets?: AssetWithCalculation[];
  pagination?: Pagination;
  months?: string[];
  count?: number;
  calcView: "calcFisc" | "calcCont";
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    pagination: {
      page: 0,
      rowsPerPage: 5,
      count: 1,
    },
    months: [],
    calcView: "calcFisc",
  },
  {}
);

export { PageContextProvider, usePageContext };
