import { useEffect } from "react";
import Datagrid from "../../../components/datagrid";
import Page from "../../../components/page";
import { useAsset } from "../../../hooks/asset";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";
import { CircularProgress } from "@mui/material";

export default function GenresPage() {
  const page = usePageContext();
  const hooks = { genres: useAsset() };
  const session = useSession();

  useEffect(() => {
    if (!page.data.genres) (async () => await getGenres())();
  }, []);

  const getGenres = async () => {
    const res = await hooks.genres.fetchGenresList(session);
    const genres = res.sort((a, b) => {
      const aCode = parseInt(a.percentage);
      const bCode = parseInt(b.percentage);
      return aCode > bCode ? 1 : bCode > aCode ? -1 : 0;
    });
    page.setData({
      genres,
    });
  };

  // const handlePageChange = async (_, newPage) => {
  //   page.setData({
  //     pagination: {
  //       ...page.data.pagination,
  //       page: newPage,
  //     },
  //   });
  // };

  // const handleChangeRowsPerPage = async (event) => {
  //   page.setData({
  //     pagination: {
  //       page: 0,
  //       rowsPerPage: event.target.value,
  //     },
  //   });
  // };

  const rows = (genres) =>
    genres.map((genre) => ({
      code: genre.id,
      percentage: `${genre.percentage}%`,
      description: genre.description,
    }));

  const columns = [
    {
      header: "Código",
      align: "right",
      columnSize: 5,
    },
    {
      header: "Depreciação",
      align: "right",
      columnSize: 5,
    },
    {
      header: "Gênero",
      align: "left",
    },
  ];

  return (
    <Page
      requiresAuthentication
      showSideBar
      showAppBar
      title="Gêneros"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Ativos", link: "/assets" },
      ]}
    >
      {!page.data.genres ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          dataLenght={page.data.genres.length}
          rows={rows(page.data.genres).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={columns}
        />
      )}
    </Page>
  );
}
