import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import List, { ListItem, ListItemText } from "../../../../components/list";
import TextField from "../../../../components/text-field/text-field";
import Page from "../../../../components/page";
import {
  Add as AddIcon,
  Clear as ClearIcon,
  Edit as EditIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { usePageContext } from "./context";
import { useLocation } from "../../../../hooks/location";
import { useSession } from "../../../../hooks/session";
import Typography from "../../../../components/typography";

const Location = ({ location, level }) => {
  const session = useSession();
  const theme = useTheme();
  const page = usePageContext();
  const hooks = {
    location: useLocation(),
  };
  const childrens = page.data.locations
    ?.filter((c) => c.parentId === location.id)
    .map((c) => <Location key={c.id} location={c} level={level + 1} />);

  const marginLeft = level * 4;

  const [temp, setTemp] = useState("");

  const deleteItem = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    location.status = "deleted";
    if (location.status === "deleted") {
      const res = await hooks.location.deleteItem(location, session);
      if (res.status === 200) {
        const index = page.data.locations.findIndex((i) => i.id === item.id);
        page.data.locations.splice(index, 1);
      } else {
        page.notify("error", "Não foi possível excluir o local");
      }
    }
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const editItem = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    location.status = "edited";
    location.view = "editing";
    setTemp(location.description);
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const saveEdit = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);

    location.description = temp;
    location.view = "default";

    page.setData({
      locations: [...page.data.locations],
    });

    if (location.status === "new") {
      hooks.location.addItem(location, session);
    } else {
      hooks.location.saveItem(location, session);
    }
    page.notify("success", "Salvo com sucesso");
  };

  const cancelEdit = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    if (location.status === "new") {
      const index = page.data.locations.findIndex((i) => i.id === item.id);
      page.data.locations.splice(index, 1);
    }
    location.view = "default";
    setTemp(location.description);
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const addSubitem = async (location) => {
    page.data.locations.push({
      parentId: location.id,
      description: "",
      status: "new",
      view: "editing",
    });
    setTemp("");
    page.setData({
      locations: [...page.data.locations],
    });
  };

  return (
    <>
      <ListItem
        id="item"
        key={location.id}
        sx={{
          padding: "0 15px",
          margin: "10px 0",
          border: "1px solid #dee2e6",
          backgroundColor: "white",
          borderRadius: "5px",
          maxWidth: theme.spacing(100 - marginLeft),
          width: "100vw",
          marginLeft: theme.spacing(marginLeft),
        }}
      >
        {location.view === "editing" && (
          <>
            <TextField
              id="location"
              name="location"
              field={{ value: temp }}
              onChange={(e) => {
                setTemp(e.target.value);
              }}
            />
            <Grid>
              <IconButton
                edge="end"
                onClick={() => {
                  cancelEdit(location);
                }}
              >
                <ClearIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => {
                  saveEdit(location);
                }}
              >
                <CheckIcon />
              </IconButton>
            </Grid>
          </>
        )}
        {location.view === "default" && (
          <>
            <ListItemText>{location.description}</ListItemText>
            <Grid>
              <IconButton
                onClick={() => {
                  deleteItem(location);
                }}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => {
                  editItem(location);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={async () => {
                  await addSubitem(location);
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </ListItem>
      {childrens}
    </>
  );
};

function LocationListPage() {
  const session = useSession();
  const page = usePageContext();
  const hooks = {
    location: useLocation(),
  };

  useEffect(() => {
    if (!page.data.locations) locationList();
  }, []);

  const locationList = async () => {
    const locations = await hooks.location.fetchLocations(session);
    page.setData({
      locations: locations.map((i) => ({
        id: i.id,
        parentId: i.parentId,
        description: i.description,
        status: i.status,
        view: i.view,
      })),
    });
  };

  const newItem = async () => {
    page.data.locations.push({
      parentId: null,
      description: "",
      status: "new",
      view: "editing",
    });
    page.setData({
      locations: [...page.data.locations],
    });
  };

  return (
    <Page
      context={page.context}
      title="Locais"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Locais", link: "/settings/location" },
      ]}
      toolbar={[
        <Button
          type="button"
          variant="contained"
          onClick={async () => {
            await newItem();
          }}
        >
          Novo
        </Button>,
      ]}
      showAppBar
      showSideBar
      requiresAuthentication
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography component={"p"} variant={"body1"}>
            Gerencie os locais onde seus ativos estão armazenados, alterando
            nome cadastrado, adicionando novos niveis ou criando um do zero!
          </Typography>
          <Typography component={"p"} variant={"body2"}>
            Segue abaixo um modelo de cadastro.
          </Typography>
        </Grid>
        <Grid item>
          <List
            sx={{
              marginTop: "10px",
            }}
          >
            {page.data.locations
              ?.filter((c) => {
                return !c.parentId;
              })
              .sort((a, b) => {
                return a.description > b.description
                  ? 1
                  : b.description > a.description
                  ? -1
                  : 0;
              })
              .map((l) => {
                return <Location key={l.id} location={l} level={0} />;
              })}
          </List>
        </Grid>
      </Grid>
    </Page>
  );
}

export default LocationListPage;
