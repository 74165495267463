import { useCallback } from "react";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { User } from "../../pages/link-user/context";
import { Session } from "../../hooks/session/session-hook.types";
import { User as EditUserType } from "../../pages/settings/edit-user/context";
import { Pagination } from "./user-hook.types";

const useUser = () => {
  const navigate = useNavigate();

  const fetchUsers = useCallback(
    async (pagination: Pagination, session?: Session) => {
      const { page, rowsPerPage } = pagination;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(
        `${config.api.url}/v2/user?page=${page}&rowsPerPage=${rowsPerPage}`,
        {
          method: "GET",
          headers: requestHeaders,
        }
      );
      const data = await res.json();
      return data;
    },
    []
  );

  const fetchSingleUser = useCallback(
    async (session?: Session, userId?: string) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(`${config.api.url}/v2/user/${userId}`, {
        method: "GET",
        headers: requestHeaders,
      });
      const data = await res.json();
      return data;
    },
    []
  );

  const editUser = useCallback(async (user: EditUserType, session: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(`${config.api.url}/v2/user/${user.id}`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify({
        name: user.name,
        email: user.email,
        active: user.active,
        role: user.editedRole,
        location: user.editedLocation,
      }),
    });

    return res;
  }, []);

  const changePassword = useCallback(
    async (
      token: string,
      newPassword: string,
      password?: string,
      isPasswordSendedByEmail?: boolean
    ) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${token || ""}`);
      const res = await fetch(`${config.api.url}/v2/user/change-password`, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify({
          password,
          newPassword,
          isPasswordSendedByEmail,
        }),
      });
      const data = res.json();
      return data;
    },
    []
  );

  const linkNewUser = useCallback(async (user: User, session: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(`${config.api.url}/v2/user/link-new-user`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        name: user.name,
        password: user.password,
      }),
    });

    return res.json();
  }, []);

  const updateUserComoanies = useCallback(async (token: string) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${token || ""}`);
    const res = await fetch(`${config.api.url}/v2/user/link-user`, {
      method: "POST",
      headers: requestHeaders,
    });

    navigate("/");
    return res;
  }, []);

  return {
    linkNewUser,
    fetchUsers,
    fetchSingleUser,
    editUser,
    changePassword,
    updateUserCompanies: updateUserComoanies,
  };
};
export { useUser };
