import { useTheme } from "@mui/material/styles";
import {
  CircularProgress,
  Divider,
  Paper,
  TextField as MuiTextField,
} from "@mui/material";
import { useEffect } from "react";
import Avatar from "../../../components/avatar";
import Grid from "../../../components/grid";
import Page from "../../../components/page";
import Typography from "../../../components/typography";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";
import TextField from "../../../components/text-field";
import Button from "../../../components/button";
import { Save as SaveIcon } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/user";

export default function ProfilePage() {
  const page = usePageContext();
  const session = useSession();
  const theme = useTheme();
  const hooks = { user: useUser() };
  const navigate = useNavigate();
  const { id } = useParams();
  const email = session.account.user.email;

  useEffect(() => {
    (async () => await loadData())();
  }, []);

  const loadData = async () => {
    await hooks.user.fetchSingleUser(session, id).then((res) => {
      page.setValues({
        name: { value: res.user.name },
        email: { value: email },
      });
      page.setData({ user: { name: res.user.name } });
    });
  };

  if (!page.data.user) {
    return <CircularProgress />;
  }

  return (
    <Page requiresAuthentication showAppBar showSideBar context={page.context}>
      <Paper sx={{ mt: 5, marginX: "auto", maxWidth: 500, p: 3 }}>
        <Grid
          container
          spacing={3}
          columns={1}
          direction={"column"}
          textAlign="center"
        >
          <Grid item>
            <Typography component={"h1"} variant={"h4"}>
              Minha Conta
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              size={100}
              sx={{
                fontSize: 70,
                marginX: "auto",
                bgcolor: theme.palette.primary.light,
              }}
            >
              {page.data.user.name[0].toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              onClick={() =>
                navigate(
                  `/change-password?passwordtoken=${session.token}&rbu=1`
                )
              }
            >
              Alterar minha senha
            </Button>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item textAlign="left">
              <Typography variant="subtitle2" sx={{ px: "14.5px" }}>
                Nome Completo:
              </Typography>
              <TextField
                field={page.values.name}
                fullWidth
                onChange={(event) => {
                  page.setValues({
                    hasChange: {
                      value: event.target.value !== page.data.user.name,
                    },
                    name: { value: event.target.value },
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                }}
              />
            </Grid>

            <Grid item textAlign="left">
              <Typography variant="subtitle2" sx={{ px: "14.5px" }}>
                E-mail:
              </Typography>
              <TextField
                field={page.values.email}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Button
                type="button"
                variant="text"
                color="info"
                onClick={() =>
                  page.values.hasChange.value
                    ? page.setValues({
                        name: { value: page.data.user.name },
                        hasChange: { value: false },
                      })
                    : navigate(-1)
                }
              >
                {page.values.hasChange.value ? "Cancelar" : "Voltar"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                onClick={async () => {
                  page.setValues({
                    isLoading: { value: true },
                  });
                  await hooks.user
                    .editUser(
                      {
                        id,
                        name: page.values.name.value,
                      },
                      session
                    )
                    .then((res) => {
                      if (res.status === 500)
                        return page.notify("error", "Algo deu errado");
                      page.notify("success", "Usuário atualizado");
                    })
                    .catch((err) =>
                      page.notify("error", `Algo deu errado - ${err}`)
                    )
                    .finally(async () => {
                      page.setValues({
                        isLoading: { value: false },
                        hasChange: { value: false },
                      });
                      await loadData();
                    });
                }}
                disabled={
                  page.values.isLoading.value || !page.values.hasChange.value
                }
                startIcon={
                  page.values.isLoading.value ? (
                    <CircularProgress color={"primary"} size={24} />
                  ) : (
                    <SaveIcon />
                  )
                }
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Page>
  );
}

// {
//   "& label.Mui-focused": {
//     color: "green",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "green",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "transparent",
//     },
//     "&:hover fieldset": {
//       borderColor: "yellow",
//     },

//   },
// }
