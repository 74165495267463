import { useEffect } from "react";
import Button from "../../../components/button/button";
import Grid from "../../../components/grid/grid";
import Page from "../../../components/page";
import {
  Edit as EditIcon,
  ForwardToInbox as ForwardToInboxIcon,
  Mail as MailIcon,
  PersonAdd as PersonAddIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";
import { useUser } from "../../../hooks/user";
import Dialog from "../../../components/dialog";
import InviteUserPage from "./components/invite-user-page";
import TextField from "../../../components/text-field";
import { useNavigate } from "react-router-dom";
import Typography from "../../../components/typography";
import Datagrid from "../../../components/datagrid";
import { useInvite } from "../../../hooks/invite";

function UserPage() {
  const session = useSession();
  const page = usePageContext();
  const hooks = { user: useUser(), invite: useInvite() };
  const navigate = useNavigate();
  const theme = useTheme();

  const createRows = (props) => {
    return props.map((user) => {
      let statusColumn = (
        <div
          style={{
            padding: 8,
            backgroundColor: "lightgreen",
            borderRadius: 8,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Ativo
        </div>
      );

      if (user.status === "Pendente") {
        statusColumn = (
          <div
            style={{
              padding: 8,
              backgroundColor: theme.palette.grey[300],
              borderRadius: 8,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Pendente
          </div>
        );
      }

      if (user.status === "Expirado") {
        statusColumn = (
          <div
            style={{
              padding: 8,
              backgroundColor: "red",
              borderRadius: 8,
              fontWeight: "bold",
              textAlign: "center",
              color: "whitesmoke",
            }}
          >
            Convite expirado
          </div>
        );
      }

      if (user.active === false && user.status === "Inativo") {
        statusColumn = (
          <div
            style={{
              padding: 8,
              backgroundColor: "red",
              borderRadius: 8,
              fontWeight: "bold",
              textAlign: "center",
              color: "whitesmoke",
            }}
          >
            Inativo
          </div>
        );
      }

      return {
        name: user.name == "" ? "Usuário não cadastrado" : user.name,
        email: (
          <Tooltip title={`Entrar em contato com ${user.email}`}>
            <div>
              <a
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  display: "flex",
                  gap: 16,
                  alignItems: "center",
                }}
                href={`mailto:${user.email}`}
              >
                {user.email}
                <MailIcon />
              </a>
            </div>
          </Tooltip>
        ),
        status: statusColumn,
        actions:
          user.status === "Expirado" ? (
            <Tooltip title="Reenviar convite">
              <div
                style={{
                  display: "flex",
                  gap: 16,
                  justifyContent: "center",
                }}
              >
                <IconButton
                  edge="end"
                  onClick={() => {
                    reSendInvite(user);
                  }}
                  color="primary"
                >
                  <ForwardToInboxIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <div style={{ display: "flex", gap: 16, justifyContent: "center" }}>
              <IconButton
                edge="end"
                disabled={user.status === "Pendente"}
                onClick={() => {
                  navigate(`./edit/${user.id}`);
                }}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </div>
          ),
      };
    });
  };

  const usersList = async () => {
    const { user: users, count } = await hooks.user.fetchUsers(
      {
        page: !page.values.pagination.value
          ? 0
          : page.values.pagination.value.page,
        rowsPerPage: !page.values.pagination.value
          ? 5
          : page.values.pagination.value.rowsPerPage,
      },
      session
    );
    if (users) {
      const filteredUsers = users.map((i) => ({
        id: i.id,
        name: i.name,
        email: i.email,
        admin: i.admin,
        active: i.active,
        status: i.status,
      }));

      page.setValues({
        pagination: {
          value: {
            count,
            page: !page.values.pagination.value
              ? 0
              : page.values.pagination.value.page,
            rowsPerPage: !page.values.pagination.value
              ? 5
              : page.values.pagination.value.rowsPerPage,
          },
        },
      });

      page.setData({
        users: users.map((i) => ({
          id: i.id,
          name: i.name,
          email: i.email,
          admin: i.admin,
          active: i.active,
        })),
        filteredUsers,
      });
    }
  };

  const handlePageChange = async (event, newPage) => {
    if (page.values.pagination.value)
      page.setValues({
        pagination: {
          value: {
            page: newPage,
            rowsPerPage: page.values.pagination.value.rowsPerPage,
            count: page.values.pagination.value.count,
          },
        },
      });

    await usersList();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (
      page.values.pagination.value?.rowsPerPage !==
      page.values.pagination.value.count
    ) {
      page.setValues({
        pagination: {
          value: {
            page: 0,
            rowsPerPage: event.target.value,
            count: page.values.pagination.value.count,
          },
        },
      });
    }

    await usersList();
  };

  const reSendInvite = async (user) => {
    const email = user.email;
    hooks.invite
      .fetchInvite(
        {
          email,
          roles: user.roles,
          locations: user.locations,
        },
        session
      )
      .then((res) => {
        if (res.errors) {
          return page.notify("error", res.errors[0].message);
        }

        return page.notify("success", "Convite re-enviado com sucesso");
      })
      .catch((e) => page.notify("error", "Falha ao enviar convite: " + e));
  };

  useEffect(() => {
    const load = async () => {
      if (!page.data.users || page.values.openDialog.value === false)
        await usersList();
    };
    load();
  }, [page.values.openDialog]);

  return (
    <Page
      title="Usuários"
      context={page.context}
      showAppBar
      showSideBar
      requiresAuthentication
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Usuários", link: "/settings/users" },
      ]}
      toolbar={[
        <Tooltip title="Convide um novo usuário por e-mail">
          <div>
            <Button
              startIcon={<PersonAddIcon />}
              type="button"
              variant="contained"
              color="primary"
              onClick={() =>
                page.setValues({
                  openDialog: { value: true },
                })
              }
            >
              Convidar
            </Button>
          </div>
        </Tooltip>,
        <Dialog
          open={page.values.openDialog.value}
          title="Convidar usuários"
          fullWidth
          maxWidth={"sm"}
        >
          <div style={{ paddingTop: 8 }}>
            <InviteUserPage />
          </div>
        </Dialog>,
      ]}
    >
      {!page.data.users ||
      !page.values.pagination ||
      !page.data.filteredUsers ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container columns={1} spacing={2} sx={{ pb: 4 }}>
          <Grid item xs={1}>
            <Typography component={"p"} variant={"body1"}>
              Veja quem já faz parte de sua empresa, e altere suas informações
              caso necessário. Caso não encontre por quem estava procurando pela
              pesquisa, envie um convite por e-mail clicando no botão
              "Convidar", depois é só preencher o e-mail e suas permissões
              inciais e pronto!
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Datagrid
              tableAppBar={
                <div style={{ padding: 16, display: "flex", gap: 16 }}>
                  <TextField
                    sx={{ flex: 1 }}
                    variant="standard"
                    field={page.values.searchInput}
                    onChange={(event) => {
                      page.setValues({
                        searchInput: { value: event.target.value },
                      });
                      let result = page.data.users.filter((user) =>
                        user.name
                          .toUpperCase()
                          .includes(page.values.searchInput.value.toUpperCase())
                      );
                      page.setData({
                        filteredUsers: result,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      placeholder: "Pesquise pelo nome de um perfil",
                    }}
                  />
                </div>
              }
              columns={[
                { header: "Nome", columnSize: 400 },
                { header: "E-mail", columnSize: 400 },
                { header: "Status" },
                { header: "Ações", columnSize: 120, align: "center" },
              ]}
              rows={createRows(page.data.filteredUsers).map((row) => ({
                cells: Object.values(row),
              }))}
              paginationProps={page.values.pagination.value}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}

export default UserPage;
