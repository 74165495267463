import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";

export interface Company {
  id: string;
  name: string;
}

export interface Data {
  view?: string;
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  { view: "Invite" },
  {}
);

export { PageContextProvider, usePageContext };
