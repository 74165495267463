import { Grid } from "@mui/material";
import Page from "../../components/page";
import { useSession } from "../../hooks/session";

function HomePage() {
  const session = useSession();

  return (
    <Page title="Home" showAppBar showSideBar requiresAuthentication>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {session.account.user.name}
        </Grid>
        <Grid item xs={12}>
          {session.account.user.email}
        </Grid>
      </Grid>
    </Page>
  );
}

export default HomePage;
