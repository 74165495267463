import { createPageContext } from "../../../../components/page";

export interface Location {
  id: string;
  parentId?: string;
  status: string;
  view: string;
  description: string;
}

export interface Data {
  locations?: Array<Location>;
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {},
  {}
);

export { PageContextProvider, usePageContext };
