import moment from "moment";
import { useCallback } from "react";
import config from "../../config";
import { Session } from "../../hooks/session/session-hook.types";
import { AssetSpot } from "../../pages/assets-menu/asset-movement/context";
import { AssetData } from "../../pages/assets-menu/assets-form/context";
import {
  AssetOcurrence,
  OcurrenceInput,
} from "../../pages/assets-menu/assets-ocurrency/context";
import { Asset } from "../../pages/assets-menu/assets/context";
import { Pagination } from "../user/user-hook.types";

const useAsset = () => {
  const fetchAssets = useCallback(
    async (session?: Session, pagination?: Pagination, searchQuery?: Asset) => {
      const { page, rowsPerPage } = pagination || { page: 0, rowsPerPage: 5 };
      const {
        description,
        number,
        rfid,
        invoiceNumber,
        location_description,
        acquireAmount,
        invoiceDate,
      } = searchQuery!;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(
        `${
          config.api.url
        }/v2/asset?page=${page}&rowsPerPage=${rowsPerPage}&description=${
          description || ""
        }&number=${number || ""}&rfid=${rfid || ""}&invoiceNumber=${
          invoiceNumber || ""
        }&location=${location_description || ""}&acquireAmountMin=${
          acquireAmount?.min || ""
        }&acquireAmountMax=${acquireAmount?.max || ""}&invoiceDateMin=${
          invoiceDate?.min || ""
        }&invoiceDateMax=${invoiceDate?.max || ""}`,
        {
          method: "GET",
          headers: requestHeaders,
        }
      );

      const data = await res.json();
      return data;
    },
    []
  );

  const fetchSingleAsset = useCallback(
    async (session?: Session, searchQuery?: Asset) => {
      const { id } = searchQuery!;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(`${config.api.url}/v2/asset/${id}`, {
        method: "GET",
        headers: requestHeaders,
      });

      const data = await res.json();
      return data;
    },
    []
  );

  const createAsset = useCallback(
    async (session?: Session, asset?: AssetData) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(`${config.api.url}/v2/asset`, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify({
          ...asset,
        }),
      });

      const data = res.json();
      return data;
    },
    []
  );

  const updateAsset = useCallback(
    async (session?: Session, asset?: AssetData) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(`${config.api.url}/v2/asset/${asset?.id}`, {
        method: "PUT",
        headers: requestHeaders,
        body: JSON.stringify({
          ...asset,
        }),
      });

      const data = res.json();
      return data;
    },
    []
  );

  const fetchMovements = useCallback(
    async (
      session?: Session,
      pagination?: Pagination,
      searchQuery?: AssetSpot
    ) => {
      const { page, rowsPerPage } = pagination || { page: 0, rowsPerPage: 5 };
      const {
        description: assetName,
        dateInMin,
        dateInMax,
        dateOutMin,
        dateOutMax,
        assetId,
        movementOnly,
      } = searchQuery!;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(
        `${
          config.api.url
        }/v2/assetspot?page=${page}&rowsPerPage=${rowsPerPage}&description=${
          assetName || ""
        }&dateInMin=${dateInMin || ""}&dateInMax=${
          dateInMax || ""
        }&dateOutMin=${dateOutMin || ""}&dateOutMax=${
          dateOutMax || ""
        }&movementOnly=${movementOnly}&assetId=${assetId || ""}`,
        {
          method: "GET",
          headers: requestHeaders,
        }
      );

      const data = await res.json();
      return data;
    },
    []
  );

  const moveAsset = useCallback(
    async (session?: Session, assetId?: string, newLocalId?: string) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(`${config.api.url}/v2/assetspot/${assetId}`, {
        method: "PUT",
        headers: requestHeaders,
        body: JSON.stringify({
          locationId: newLocalId,
        }),
      });

      const data = res.json();
      return data;
    },
    []
  );

  const fetchOcurrence = useCallback(
    async (
      session?: Session,
      pagination?: Pagination,
      searchQuery?: AssetOcurrence
    ) => {
      const { page, rowsPerPage } = pagination || { page: 0, rowsPerPage: 5 };
      const {
        assetId,
        acquireAmountMin,
        acquireAmountMax,
        createdAt,
        updatedAt,
        futureSellAmountMin,
        futureSellAmountMax,
        invoiceDate,
        remainingAmountMin,
        remainingAmountMax,
        yearsToDepreciate,
        endDate,
        startDate,
        assetName,
      } = searchQuery!;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(
        `${
          config.api.url
        }/v2/assetoccurrence?page=${page}&rowsPerPage=${rowsPerPage}&acquireAmountMin=${
          acquireAmountMin || ""
        }&acquireAmountMax=${acquireAmountMax || ""}&createdAt=${
          createdAt || ""
        }&updatedAt=${updatedAt || ""}&futureSellAmountMin=${
          futureSellAmountMin || ""
        }&futureSellAmountMax=${futureSellAmountMax || ""}&invoiceDate=${
          invoiceDate || ""
        }&remainingAmountMin=${remainingAmountMin || ""}&remainingAmountMax=${
          remainingAmountMax || ""
        }&yearsToDepreciate=${yearsToDepreciate || ""}&endDate=${
          endDate || ""
        }&startDate=${startDate || ""}&assetId=${assetId || ""}&assetName=${
          assetName || ""
        }`,
        {
          method: "GET",
          headers: requestHeaders,
        }
      );

      const data = await res.json();
      return data;
    },
    []
  );

  const newOcurrence = useCallback(
    async (
      ocurrenceInput: OcurrenceInput,
      assetId: string,
      session?: Session
    ) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const res = await fetch(
        `${config.api.url}/v2/assetoccurrence/${assetId}`,
        {
          method: "PUT",
          headers: requestHeaders,
          body: JSON.stringify(ocurrenceInput),
        }
      );

      const data = res.json();
      return data;
    },
    []
  );

  const fetchGenresList = useCallback(async (session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

    const res = await fetch(`${config.api.url}/v2/genre`, {
      method: "GET",
      headers: requestHeaders,
    });

    const data = res.json();
    return data;
  }, []);

  const fetchCalculatedDepreciation = useCallback(
    async (session?: Session, pagination?: Pagination, dateEnd?: Date) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
      const { page, rowsPerPage } = pagination ?? { page: 0, rowsPerPage: 5 };
      const res = await fetch(
        `${
          config.api.url
        }/v2/asset-calc?page=${page}&rowsPerPage=${rowsPerPage}&dateEnd=${
          dateEnd ?? moment().endOf("year")
        }`,
        {
          method: "GET",
          headers: requestHeaders,
        }
      );

      const data = res.json();
      return data;
    },
    []
  );

  return {
    fetchAssets,
    fetchSingleAsset,
    createAsset,
    updateAsset,
    fetchMovements,
    moveAsset,
    fetchOcurrence,
    newOcurrence,
    fetchGenresList,
    fetchCalculatedDepreciation,
  };
};

export { useAsset };
