import { useEffect, useState } from "react";
import Datagrid from "../../../components/datagrid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import { useAsset } from "../../../hooks/asset";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";
import moment from "moment";
import {
  InputAdornment,
  Menu as MuiMenu,
  IconButton as MuiIconButton,
  Divider as MuiDivider,
  MenuItem as MuiMenuItem,
  Tooltip as MuiTooltip,
  CircularProgress,
} from "@mui/material";
import {
  DescriptionOutlined as DescriptionIcon,
  DocumentScannerOutlined as DocumentScannerOutlinedIcon,
  FilterList,
  PaidOutlined as PaidIcon,
  ReceiptOutlined as ReceiptIcon,
  ApartmentOutlined as ApartmentOutlinedIcon,
  Add as AddIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  FilterListOff as FilterListOffIcon,
  MoveDownOutlined as MoveDownOutlinedIcon,
  TimelineOutlined as TimelineOutlinedIcon,
} from "@mui/icons-material";
import Typography from "../../../components/typography";
import Button from "../../../components/button";
import Grid from "../../../components/grid";
import { useNavigate } from "react-router-dom";

const SearchField = ({
  initialValue,
  queryKey,
  Icon,
  label,
  placeholder,
  iconPosition,
  size,
}) => {
  const [searchValue, setSearchValue] = useState(initialValue);
  const page = usePageContext();
  useEffect(() => {
    if (searchValue !== initialValue) {
      const timeOutId = setTimeout(
        () =>
          page.setData({
            searchQueryParams: {
              ...page.data.searchQueryParams,
              [queryKey]: searchValue,
            },
            pagination: {
              page: 0,
              rowsPerPage: 5,
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  const doSearchOnChange = async (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <TextField
      variant="standard"
      placeholder={placeholder}
      label={label}
      fullWidth
      size={size}
      field={{ value: searchValue }}
      onChange={doSearchOnChange}
      type="search"
      InputProps={{
        [`${iconPosition}Adornment`]: (
          <InputAdornment position={iconPosition}>{Icon}</InputAdornment>
        ),
      }}
    />
  );
};

export default function AssetsPage() {
  const page = usePageContext();
  const hooks = { assets: useAsset() };
  const session = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (!page.data.assets || page.data.pagination)
      (async () => await getAssets())();
  }, [page.data.pagination, page.data.searchQueryParams]);

  const getAssets = async () => {
    const { count, asset: assets } = await hooks.assets.fetchAssets(
      session,
      page.data.pagination,
      page.data.searchQueryParams
    );
    page.setData({
      count,
      assets,
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const rows = (assets) =>
    assets.map((asset) => ({
      description: asset.description,
      date: moment(asset.invoiceDate).format("DD/MM/YYYY"),
      value: asset.acquireAmount.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      }),
      local: asset.location_description,
      number: asset.number,
      rfid: asset.rfid,
      nf: asset.invoiceNumber,
      actions: asset.acquireAmount ? (
        <>
          <MuiTooltip title="Editar ativo" sx={{ mr: 0.5 }}>
            <MuiIconButton
              edge="end"
              onClick={() => navigate(`./form?id=${asset.id}`)}
              color="primary"
            >
              <EditIcon />
            </MuiIconButton>
          </MuiTooltip>
          <MuiTooltip title="Ver movimentações" sx={{ mr: 0.5 }}>
            <MuiIconButton
              edge="end"
              onClick={() => navigate(`./movimentacao?id=${asset.id}`)}
              color="primary"
            >
              <MoveDownOutlinedIcon />
            </MuiIconButton>
          </MuiTooltip>
          <MuiTooltip title="Ver histórico">
            <MuiIconButton
              edge="end"
              onClick={() => navigate(`./ocorrencias?id=${asset.id}`)}
              color="primary"
            >
              <TimelineOutlinedIcon />
            </MuiIconButton>
          </MuiTooltip>
        </>
      ) : (
        <>
          <MuiTooltip title="Editar ativo" sx={{ mr: 0.5 }}>
            <div>
              <Button
                type="button"
                variant="text"
                onClick={() => navigate(`./form?id=${asset.id}`)}
                startIcon={<EditIcon />}
                color="warning"
              >
                {" "}
                finalizar cadastro
              </Button>
            </div>
          </MuiTooltip>
        </>
      ),
    }));

  const columns = [
    {
      header: "Descrição",
      align: "left",
      columnSize: 200,
    },
    {
      header: "Data aquisição",
      align: "left",
    },
    {
      header: "Valor aquisição",
      align: "right",
    },
    {
      header: "Local",
      align: "left",
    },
    {
      header: "Patrimônio",
      align: "right",
    },
    {
      header: "RFID",
      align: "left",
    },
    {
      header: "Documento",
      align: "right",
    },
    {
      header: "Ações",
      align: "center",
      columnSize: 150,
    },
  ];

  const FilterMenu = ({ anchorEl, handleClose }) => {
    const open = Boolean(anchorEl);
    const menuId = open ? "simple-menu" : undefined;
    let min;
    let max;
    let minDate;
    let maxDate;

    if (page.data.searchQueryParams.acquireAmount) {
      min = page.data.searchQueryParams.acquireAmount.min;
      max = page.data.searchQueryParams.acquireAmount.max;
    }
    const [acquireAmountMin, setMin] = useState(min || 0);
    const [acquireAmountMax, setMax] = useState(max || 0);

    if (page.data.searchQueryParams.invoiceDate) {
      minDate = page.data.searchQueryParams.invoiceDate.min;
      maxDate = page.data.searchQueryParams.invoiceDate.max;
    }

    const [dateMin, setMinDate] = useState(minDate);
    const [dateMax, setMaxDate] = useState(maxDate);

    return (
      <MuiMenu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div
          style={{
            padding: "0 16px ",
          }}
        >
          <Typography component={"h2"} variant={"h6"}>
            Filtros
          </Typography>
          <MuiDivider />
          <Grid container spacing={1} direction={"column"} sx={{ mt: 1 }}>
            <Grid item>
              <Typography component={"h2"} variant={"subtitle2"}>
                Data aquisição
              </Typography>
              <MuiMenuItem
                sx={{ gap: 1 }}
                style={{
                  backgroundColor: "transparent",
                }}
                disableTouchRipple
                disableRipple
                disableGutters
                unselectable="off"
              >
                <DateSearchField
                  label={"inicial"}
                  value={dateMin}
                  onChange={(event) => setMinDate(event.target.value)}
                />
                até
                <DateSearchField
                  label={"final"}
                  value={dateMax}
                  onChange={(event) => setMaxDate(event.target.value)}
                />
              </MuiMenuItem>
            </Grid>
            <Grid item>
              <Typography component={"h2"} variant={"subtitle2"}>
                Valor da aquisição
              </Typography>
              <MuiMenuItem
                sx={{ gap: 1 }}
                style={{
                  backgroundColor: "transparent",
                }}
                disableTouchRipple
                disableRipple
                disableGutters
                unselectable="off"
              >
                <ValueSearchField
                  label={"Min."}
                  value={acquireAmountMin}
                  onChange={(event) => setMin(event.target.value)}
                />
                até
                <ValueSearchField
                  label={"Max."}
                  value={acquireAmountMax}
                  onChange={(event) => setMax(event.target.value)}
                />
              </MuiMenuItem>
            </Grid>
            <Grid item textAlign="right">
              <Button
                type={"button"}
                variant={"outlined"}
                sx={{
                  marginRight: 1,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  page.setData({
                    searchQueryParams: {
                      ...page.data.searchQueryParams,
                      acquireAmount: {
                        max: null,
                        min: null,
                      },
                      invoiceDate: {
                        min: null,
                        max: null,
                      },
                    },
                  });
                }}
              >
                Cancelar
              </Button>
              <Button
                startIcon={<SearchIcon />}
                type={"button"}
                onClick={() =>
                  page.setData({
                    searchQueryParams: {
                      ...page.data.searchQueryParams,
                      acquireAmount: {
                        max: acquireAmountMax,
                        min: acquireAmountMin,
                      },
                      invoiceDate: {
                        min: dateMin,
                        max: dateMax,
                      },
                    },
                  })
                }
              >
                Aplicar filtros
              </Button>
            </Grid>
          </Grid>
        </div>
      </MuiMenu>
    );
  };

  const FilterButton = ({ Icon }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <MuiIconButton
          onClick={handleClick}
          sx={{
            marginRight: "15px",
            color: "inherit",
          }}
        >
          <Icon />
        </MuiIconButton>
        <FilterMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
      </>
    );
  };

  const ClearFiltersButton = () => {
    return (
      <MuiTooltip title="Limpar todos os filtros">
        <MuiIconButton
          onClick={() =>
            page.setData({
              searchQueryParams: {
                acquireAmount: {
                  min: null,
                  max: null,
                },
                description: "",
                invoiceDate: {
                  max: null,
                  min: null,
                },
                invoiceNumber: null,
                location_description: null,
                number: null,
                rfid: null,
              },
            })
          }
        >
          <FilterListOffIcon />
        </MuiIconButton>
      </MuiTooltip>
    );
  };

  const DateSearchField = ({ label, value, onChange }) => {
    return (
      <TextField
        variant="outlined"
        label={label}
        fullWidth
        size={"small"}
        type="date"
        field={{ value: value }}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };

  const ValueSearchField = ({ label, value, onChange }) => {
    const fieldValue = !value || value < 0 ? 0 : value;
    return (
      <TextField
        variant="outlined"
        placeholder="0,00"
        label={label}
        fullWidth
        size={"small"}
        name="acquireAmount"
        id="acquireAmount"
        field={{
          value: parseFloat(fieldValue).toFixed(2),
        }}
        onChange={onChange}
        type={"number"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PaidIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <Page
      requiresAuthentication
      showSideBar
      showAppBar
      title="Ativos"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Ativos", link: "/assets" },
      ]}
      toolbar={[
        <MuiTooltip title="Crie um novo ativo">
          <div>
            <Button
              startIcon={<AddIcon />}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => navigate("../form")}
            >
              Novo Ativo
            </Button>
          </div>
        </MuiTooltip>,
      ]}
    >
      {!page.data.assets ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          tableAppBar={
            <div style={{ padding: 16, display: "flex", gap: 16 }}>
              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.description}
                queryKey={"description"}
                Icon={<DescriptionIcon />}
                label={"Descrição"}
                placeholder={"Ex: Monitor, computador..."}
              />{" "}
              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.location_description}
                queryKey={"location_description"}
                Icon={<ApartmentOutlinedIcon />}
                label={"Local"}
                placeholder={"Localização atual do item"}
              />
              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.number}
                queryKey={"number"}
                Icon={<DocumentScannerOutlinedIcon />}
                label={"Patrimônio"}
                placeholder={"Número interno de controle da empresa"}
              />
              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.invoiceNumber}
                queryKey={"invoiceNumber"}
                Icon={<ReceiptIcon />}
                label={"Nota Fiscal"}
                placeholder={"NF em que o ativo foi faturado"}
              />
              <div style={{ display: "flex" }}>
                <FilterButton Icon={FilterList} />
                <ClearFiltersButton />
              </div>
            </div>
          }
          dataLenght={page.data.count}
          rows={rows(page.data.assets).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={columns}
          paginationProps={{
            ...page.data.pagination,
            count: page.data.count,
          }}
          handlePageChange={async (event, page) => {
            await handlePageChange(event, page);
          }}
          handleChangeRowsPerPage={async (event) => {
            await handleChangeRowsPerPage(event);
          }}
        />
      )}
    </Page>
  );
}
