import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";
import { Location } from "../../settings/users/context";

export interface AssetSpot {
  id?: string;
  assetId?: string;
  assetRfid?: string;
  location?: string;
  description?: string;
  dateInMin?: Date;
  dateInMax?: Date;
  dateOutMin?: Date;
  dateOutMax?: Date;
  movementOnly?: boolean;
}

export interface Data {
  assets?: AssetSpot[];
  count?: number;
  locals?: Location[];
  pagination?: Pagination;
  searchQueryParams?: AssetSpot;
  dialog: {
    isOpen: boolean;
    title: string;
    local?: string;
    selectedAsset?: string;
  };
}

export interface Values {
  local?: PageField;
  newLocal?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    dialog: { isOpen: false, title: "" },
    pagination: {
      page: 0,
      rowsPerPage: 5,
      count: 1,
    },
    searchQueryParams: {
      movementOnly: false,
    },
  },
  {
    local: createPageField(undefined, { required: true }),
    newLocal: createPageField(undefined, { required: true }),
  }
);

export { PageContextProvider, usePageContext };
