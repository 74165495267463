import { useSession } from "../../../../hooks/session";
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
  Divider as MuiDivider,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
  SwapVert as SwapVertIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../avatar";
import { useTheme } from "@mui/material";
import Typography from "../../../typography";

const AccountMenu = ({ anchorEl, handleClose, openDialog }) => {
  const open = Boolean(anchorEl);
  const menuId = open ? "simple-menu" : undefined;
  const hooks = { auth: useSession() };
  const navigate = useNavigate();
  const session = useSession();
  const theme = useTheme();
  const firstName = session.account.user.name.split(" ")[0];
  return (
    <MuiMenu
      id={menuId}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          textAlign: "center",
          gap: 8,
        }}
      >
        <Typography component={"h2"} variant={"h6"}>
          {firstName}
        </Typography>
        <Avatar
          sx={{
            margin: "0 auto",
            fontSize: 32,
            bgcolor: theme.palette.primary.dark,
          }}
          size={64}
        >
          {firstName[0]}
        </Avatar>

        <Typography component={"h2"} variant={"subtitle1"}>
          {session.account.selectedCompany.name}
        </Typography>
      </div>
      <MuiDivider sx={{ my: 1 }} />
      <MuiMenuItem
        onClick={() => navigate(`/profile/${session.account.user.id}`)}
      >
        <MuiListItemIcon>
          <AccountCircleIcon />
        </MuiListItemIcon>
        Minha conta
      </MuiMenuItem>
      <MuiMenuItem
        sx={{
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        }}
        onClick={() => {
          openDialog();
          handleClose();
        }}
      >
        <MuiListItemIcon>
          <SwapVertIcon />
        </MuiListItemIcon>
        Trocar de empresa
      </MuiMenuItem>
      <MuiMenuItem
        onClick={() => {
          hooks.auth.signOut();
        }}
      >
        <MuiListItemIcon>
          <LogoutIcon />
        </MuiListItemIcon>
        Logout
      </MuiMenuItem>
    </MuiMenu>
  );
};
export default AccountMenu;
