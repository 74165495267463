import { createTheme, ThemeProvider } from "@mui/material/styles";
import Routes from "../pages/routes";
import { AppContextProvider } from "../hooks/app";
import { SessionContextProvider } from "../hooks/session";

let theme = createTheme({});

theme = createTheme({
  palette: {
    primary: {
      main: "#048CB0",
    },
    text: {
      primary: "#3F6073",
    },
  },
  components: {
    AppBar: {
      height: 64,
    },
    SideBar: {
      widthWhenIsOpen: 240,
      widthWhenIsCollapsed: theme.spacing(7),
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <SessionContextProvider>
            <Routes />
          </SessionContextProvider>
        </AppContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
