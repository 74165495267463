import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
} from "@mui/material";
import { SxProps } from "@mui/material/styles";

export interface ListItemTextProps {
  id?: string;
  sx?: SxProps;
  children: JSX.Element;
}

function ListItemText(props: ListItemTextProps): JSX.Element {
  const muiListItemTextProps: MuiListItemTextProps = {};

  if (props.id) {
    muiListItemTextProps.id = props.id;
  }

  if (props.sx)
    muiListItemTextProps.sx = {
      ...props.sx,
    };

  return (
    <MuiListItemText {...muiListItemTextProps}>
      {props.children}
    </MuiListItemText>
  );
}

export { ListItemText };
