import { useEffect } from "react";
import Datagrid from "../../../components/datagrid";
import Page from "../../../components/page";
import { useAsset } from "../../../hooks/asset";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Button from "../../../components/button";
import { SwapVertOutlined as SwapIcon } from "@mui/icons-material";
import TextField from "../../../components/text-field";

// const SearchField = ({
//   initialValue,
//   queryKey,
//   Icon,
//   label,
//   placeholder,
//   iconPosition,
//   size,
// }) => {
//   const [searchValue, setSearchValue] = useState(initialValue);

//   useEffect(() => {
//     if (searchValue !== initialValue) {
//       const timeOutId = setTimeout(
//         () =>
//           page.setData({
//             searchQueryParams: {
//               ...page.data.searchQueryParams,
//               [queryKey]: searchValue,
//             },
//             pagination: {
//               page: 0,
//               rowsPerPage: 5,
//             },
//           }),
//         500
//       );
//       return () => clearTimeout(timeOutId);
//     }
//   }, [searchValue]);

//   const doSearchOnChange = async (event) => {
//     setSearchValue(event.target.value);
//   };
//   return (
//     <TextField
//       variant="standard"
//       placeholder={placeholder}
//       label={label}
//       fullWidth
//       size={size}
//       field={{ value: searchValue }}
//       onChange={doSearchOnChange}
//       type="search"
//       InputProps={{
//         [`${iconPosition}Adornment`]: (
//           <InputAdornment position={iconPosition}>{Icon}</InputAdornment>
//         ),
//       }}
//     />
//   );
// };

export default function CalcPage() {
  const page = usePageContext();
  const hooks = { calc: useAsset() };
  const session = useSession();

  useEffect(() => {
    if (!page.data.calculatedAssets || page.data.pagination)
      (async () => await getCalculatedAssets())();
  }, [page.data.pagination]);

  const getCalculatedAssets = async () => {
    const calculatedAssets = await hooks.calc.fetchCalculatedDepreciation(
      session,
      page.data.pagination
    );

    page.setData({
      calculatedAssets: calculatedAssets.asset,
      count: calculatedAssets.count,
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const rows = (assets) =>
    assets.map((asset) => {
      const sellValue = asset.sellValue ?? 0;
      const rows = {
        description: asset.description,
        acquirementDate: moment(asset.acquirementDate).format("DD/MM/YYYY"),
        depreciation: `${asset.depreciation} %`,
        yearsToDepreciate: `${asset.yearsToDepreciate} Anos`,
        acquirementValue: asset.acquirementValue.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
        accountingValue: parseFloat(asset.accountingValue).toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          }
        ),
        sellValue: sellValue.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
        despicableValue: parseFloat(asset.despicableValue).toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          }
        ),
        depreciationPercentageAtAccountingDate: `${asset.depreciationPercentageAtAccountingDate} %`,
        depreciationValueAtAccountingDate: parseFloat(
          asset.depreciationValueAtAccountingDate
        ).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
      };

      asset[page.data.calcView].forEach((calc) => {
        rows[calc.month] = parseFloat(calc.percentInThisMonth).toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          }
        );
      });

      return rows;
    });

  const generateColumns = (calculatedAssets) => {
    const months =
      calculatedAssets.length === 0
        ? []
        : calculatedAssets[0][page.data.calcView].map((month) => month.month);

    return [
      {
        header: "Ativo",
        align: "left",
        columnSize: 300,
      },
      {
        header: "Data de aquisição",
        align: "left",
        columnSize: 150,
      },
      {
        header: "Depreciação",
        align: "right",
      },
      {
        header: "Anos para depreciação",
        align: "right",
      },
      {
        header: "Valor da aquisição",
        align: "right",
      },
      {
        header: "Valor contábil",
        align: "right",
      },
      {
        header: "Valor de venda",
        align: "right",
      },
      { header: "Valor depreciável", align: "right" },
      {
        header: "Depreciação acumulada %",
        align: "right",
      },
      {
        header: "Depreciação acumulada $",
        align: "right",
      },

      ...months.map((month) => ({
        header: month,
        align: "right",
      })),
    ];
  };

  return (
    <Page
      requiresAuthentication
      showSideBar
      showAppBar
      title={`Cálculo ${
        page.data.calcView === "calcFisc" ? "Fiscal" : "Contábil"
      }`}
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Ativos", link: "/assets" },
      ]}
      toolbar={[
        <Button
          type="button"
          startIcon={<SwapIcon />}
          onClick={() =>
            page.setData({
              calcView:
                page.data.calcView === "calcFisc" ? "calcCont" : "calcFisc",
            })
          }
        >
          {`Alterar para cálculo ${
            page.data.calcView === "calcCont" ? "Fiscal" : "Contábil"
          }`}
        </Button>,
      ]}
    >
      {!page.data.calculatedAssets ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          dataLenght={page.data.calculatedAssets.length}
          rows={rows(page.data.calculatedAssets).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={generateColumns(page.data.calculatedAssets)}
          paginationProps={{ ...page.data.pagination, count: page.data.count }}
          handlePageChange={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Page>
  );
}
