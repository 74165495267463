import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from "@mui/material";
import { SxProps } from "@mui/material/styles";

export interface ListItemButtonProps {
  id?: string;
  sx?: SxProps;
  selected?: boolean;
  children: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function ListItemButton(props: ListItemButtonProps): JSX.Element {
  const muiListItemButtonProps: MuiListItemButtonProps = {};

  if (props.id) {
    muiListItemButtonProps.id = props.id;
    muiListItemButtonProps.key = props.id;
  }

  if (props.selected) muiListItemButtonProps.selected = props.selected;

  if (props.sx)
    muiListItemButtonProps.sx = {
      ...props.sx,
    };

  if (props.onClick) muiListItemButtonProps.onClick = props.onClick;

  return (
    <MuiListItemButton {...muiListItemButtonProps}>
      {props.children}
    </MuiListItemButton>
  );
}

export { ListItemButton };
