import { useEffect, useState } from "react";
import Datagrid from "../../../components/datagrid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import { useAsset } from "../../../hooks/asset";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";
import moment from "moment";
import {
  InputAdornment,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  CircularProgress,
  Menu as MuiMenu,
  Divider as MuiDivider,
  MenuItem as MuiMenuItem,
  Switch as MuiSwitch,
} from "@mui/material";
import {
  DescriptionOutlined as DescriptionIcon,
  FilterListOff as FilterListOffIcon,
  NearMe as NearMeIcon,
  SellOutlined as TagIcon,
  FilterList,
  DateRangeOutlined as DateRangeOutlinedIcon,
  East as EastIcon,
  OpenInNewOutlined,
  Search as SearchIcon,
} from "@mui/icons-material";
import Typography from "../../../components/typography";
import Button from "../../../components/button";
import Grid from "../../../components/grid";
import { useNavigate, useSearchParams } from "react-router-dom";
import Dialog from "../../../components/dialog";
import { useLocation } from "../../../hooks/location";
import AutoComplete from "../../../components/auto-complete";

export default function AssetsOcurrencePage() {
  const page = usePageContext();
  const hooks = { assets: useAsset(), local: useLocation() };
  const session = useSession();
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useSearchParams();
  const assetId = queryParams.get("id");

  useEffect(() => {
    if (!page.data.assets || page.data.pagination)
      (async () => await getAssetOcurrencies())();
  }, [page.data.pagination, page.data.searchQueryParams]);

  const getAssetOcurrencies = async () => {
    const { count, assetOccurrence: assets } =
      await hooks.assets.fetchOcurrence(session, page.data.pagination, {
        ...page.data.searchQueryParams,
        assetId,
      });
    page.setData({
      count,
      assets,
    });
  };

  const getAllOcurrencies = () => {
    setQueryParams("");
    page.setData({
      searchQueryParams: {
        ...page.data.searchQueryParams,
        assetId: "",
      },
    });
  };

  const moveAsset = async () => {
    await hooks.assets.moveAsset(
      session,
      page.data.dialog.selectedAsset,
      page.values.newLocal.value.id
    );
    page.notify("success", "Ativo movido com sucesso", async () => {
      await getAssetOcurrencies();
      page.setData({
        dialog: {
          isOpen: false,
        },
        pagination: {
          page: 0,
          rowsPerPage: 5,
        },
      });
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const rows = (assets) =>
    assets.map((asset) => ({
      description: (
        <MuiTooltip title="Ver ativo">
          <div>
            <Button
              endIcon={<OpenInNewOutlined />}
              onClick={() => navigate(`../form?id=${asset.assetId}`)}
              variant={"text"}
            >
              {asset.assetName}
            </Button>
          </div>
        </MuiTooltip>
      ),
      acquired_amount: asset.acquiredAmount.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      }),
      future_sell_amount: asset.futureSellAmount.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      }),
      years_to_depreciate: asset.yearsToDepreciate,
      remaining_amount: asset.remainingAmount.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      }),
      start_date: moment(asset.startDate).format("DD/MM/YYYY"),
      end_date: asset.endDate
        ? moment(asset.endDate).format("DD/MM/YYYY")
        : "Sem ocorrência",
      invoice_date: moment(asset.invoiceDate).format("DD/MM/YYYY"),
    }));

  const columns = [
    {
      header: "Ativo",
      align: "left",
    },
    {
      header: "Valor Aquisição",
      align: "right",
    },
    {
      header: "Valor futuro de venda",
      align: "right",
    },
    {
      header: "Anos para depreciação",
      align: "right",
    },
    {
      header: "Quantia restante",
      align: "right",
    },
    {
      header: "Data inicial",
      align: "left",
    },
    {
      header: "Data final",
      align: "left",
    },
    {
      header: "Data do Faturamento",
      align: "left",
    },
  ];

  const SearchField = ({
    initialValue,
    queryKey,
    Icon,
    label,
    placeholder,
    iconPosition,
    size,
  }) => {
    const [searchValue, setSearchValue] = useState(initialValue);

    useEffect(() => {
      if (searchValue !== initialValue) {
        const timeOutId = setTimeout(
          () =>
            page.setData({
              searchQueryParams: {
                ...page.data.searchQueryParams,
                [queryKey]: searchValue,
              },
              pagination: {
                page: 0,
                rowsPerPage: 5,
              },
            }),
          500
        );
        return () => clearTimeout(timeOutId);
      }
    }, [searchValue]);

    const doSearchOnChange = async (event) => {
      setSearchValue(event.target.value);
    };
    return (
      <TextField
        variant="standard"
        placeholder={placeholder}
        label={label}
        fullWidth
        size={size}
        field={{ value: searchValue }}
        onChange={doSearchOnChange}
        type="search"
        InputProps={{
          [`${iconPosition}Adornment`]: (
            <InputAdornment position={iconPosition}>{Icon}</InputAdornment>
          ),
        }}
      />
    );
  };

  const DateSearchField = ({ label, value, onChange, variant, size }) => {
    return (
      <TextField
        variant={variant ?? "outlined"}
        label={label}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeOutlinedIcon />
            </InputAdornment>
          ),
        }}
        size={size}
        type="date"
        field={{ value: value }}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };

  return (
    <Page
      context={page.context}
      requiresAuthentication
      showSideBar
      showAppBar
      title="Ocorrências de Ativos"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Ativos", link: "/assets" },
        { description: "Movimentção de Ativos", link: "/assets/movimentacao" },
      ]}
      toolbar={[
        <Button type="button" onClick={getAllOcurrencies}>
          Mostrar todos
        </Button>,
        <Dialog
          open={page.data.dialog.isOpen}
          title={page.data.dialog.title}
          fullWidth
          maxWidth={"sm"}
        >
          <Grid container spacing={3} sx={{ pt: 1 }}>
            <Grid item xs={6}>
              <AutoComplete
                id="local"
                disabled
                label="Local atual"
                field={page.values.local}
                options={page.data.locals ?? []}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                onChange={(_, val) => {
                  page.setValues({
                    local: {
                      value: val,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoComplete
                id="local-novo"
                label="Novo local"
                field={page.values.newLocal}
                options={page.data.locals ?? []}
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                onChange={(_, val) => {
                  page.setValues({
                    newLocal: {
                      value: val,
                    },
                  });
                }}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={1}
              sx={{ justifyContent: "right" }}
            >
              <Grid item>
                <Button
                  type="button"
                  variant="text"
                  color="warning"
                  onClick={() => {
                    page.setData({ dialog: { isOpen: false } });
                    page.setValues({ newLocal: { value: null } });
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  endIcon={<EastIcon />}
                  onClick={async () => await moveAsset()}
                >
                  Mover
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>,
      ]}
    >
      {!page.data.assets ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container columns={1} spacing={2} sx={{ pb: 4 }}>
          <Grid item xs={1}>
            <Typography component={"p"} variant={"body1"}>
              Acompanhe a movimentação de seus ativos e altere eles de local
              quando necessário. Busque pela descrição ou filtre por período de
              data de entrada ou saída
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Datagrid
              dataLenght={page.data.count}
              rows={rows(page.data.assets).map((row) => ({
                cells: Object.values(row),
              }))}
              columns={columns}
              tableAppBar={
                !queryParams.get("id") && (
                  <div style={{ padding: 16, display: "flex", gap: 16 }}>
                    <SearchField
                      iconPosition={"start"}
                      initialValue={page.data.searchQueryParams.assetName}
                      queryKey={"assetName"}
                      Icon={<SearchIcon />}
                      placeholder={"Ex: Monitor, computador..."}
                    />
                  </div>
                )
              }
              paginationProps={{
                ...page.data.pagination,
                count: page.data.count,
              }}
              handlePageChange={async (event, page) => {
                await handlePageChange(event, page);
              }}
              handleChangeRowsPerPage={async (event) => {
                await handleChangeRowsPerPage(event);
              }}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}
