import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from "@mui/material";
import { CheckboxFieldProps } from "./checkbox-field.types";

function CheckboxField(props: CheckboxFieldProps) {
  const muiCheckboxProps: MuiCheckboxProps = {
    checked: props.field.value === true,
    value: props.field.value === true ? "on" : "off",
  };

  if (props.id) {
    muiCheckboxProps.value = muiCheckboxProps.id = `${props.id}_cbx`;
    muiCheckboxProps.name = muiCheckboxProps.id;
  }

  const muiFormControlLabelProps: MuiFormControlLabelProps = {
    id: `${props.id}_cbx_lbl`,
    name: `${props.id}_cbx_lbl`,
    control: <MuiCheckbox {...muiCheckboxProps} />,
    label: props.label,
  };

  if (props.onChange) muiCheckboxProps.onChange = props.onChange;

  return (
    <MuiFormControlLabel
      {...muiFormControlLabelProps}
      control={<MuiCheckbox {...muiCheckboxProps} />}
    />
  );
}

export default CheckboxField;
