import { BrowserRouter, Route, Routes as RouterRoutes } from "react-router-dom";

// import cookies from "../cookies";

import HomePage from "./home";
import SignInPage from "./signin";
import SettingsPage from "./settings/settings-page";
import SettingsLocationListPage from "./settings/location/list";
import SettingsLocationDetailPage from "./settings/location/detail";
import SettingsRolePage from "./settings/role";
import LinkUserPage from "./link-user";
import AccessPage from "./settings/access";
import UserPage from "./settings/users";
import ChangePassword from "./change-password";
import SettingsEditUserPage from "./settings/edit-user";
import UpdateUserInvite from "./signin/update-user-invite";
import ProfilePage from "./settings/profile";
import AssetsPage from "./assets-menu/assets";
import AssetsFormPage from "./assets-menu/assets-form";
import AssetsMovementPage from "./assets-menu/asset-movement";
import AssetsOcurrencyPage from "./assets-menu/assets-ocurrency";
import GenresPage from "./settings/genres";
import CalcPage from "./settings/calculation";

const Routes = () => {
  const PageNotFound = () => {
    return <h1>Page not found</h1>;
  };

  return (
    <BrowserRouter>
      <RouterRoutes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="signin" element={<SignInPage />} />
          <Route path="profile/:id" element={<ProfilePage />} />

          <Route path="assets">
            <Route index element={<AssetsPage />} />
            <Route path="form" element={<AssetsFormPage />} />
            <Route path="movimentacao" element={<AssetsMovementPage />} />
            <Route path="ocorrencias" element={<AssetsOcurrencyPage />} />
          </Route>

          <Route path="calc" element={<CalcPage />} />

          <Route path="settings">
            <Route index element={<SettingsPage />} />
            <Route path="location" element={<SettingsLocationListPage />} />
            <Route
              path="location/insert"
              element={<SettingsLocationDetailPage />}
            />
            <Route
              path="location/:id"
              element={<SettingsLocationDetailPage />}
            />
            <Route path="roles" element={<SettingsRolePage />} />
            <Route path="access/:id" element={<AccessPage />} />
            <Route path="users">
              <Route index element={<UserPage />} />
              <Route path="edit/:id" element={<SettingsEditUserPage />} />
            </Route>
            <Route path="genres" element={<GenresPage />} />
          </Route>

          <Route path="link-user/" element={<UpdateUserInvite />} />
          <Route path="link-new-user" element={<LinkUserPage />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </RouterRoutes>
    </BrowserRouter>
  );
};

export default Routes;
