import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";

export interface Asset {
  id?: string;
  description?: string;
  invoiceDate?:{ min: Date; max: Date } ;
  acquireAmount?: { min: number; max: number };
  location_description?: string;
  number?: string;
  rfid?: string;
  invoiceNumber?: string;
}

export interface Data {
  assets?: Asset[];
  count?: number;
  pagination?: Pagination;
  searchQueryParams?: Asset;
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    pagination: {
      page: 0,
      rowsPerPage: 5,
      count: 1,
    },
    searchQueryParams: {},
  },
  {}
);

export { PageContextProvider, usePageContext };
