import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";

export interface Role {
  id: string;
  name: string;
  status: string;
  view: string;
}

export interface Access {
  id: string;
  name: string;
  roleAccess: boolean;
  vincAccessId?: string;
}

export interface Data {
  roles?: Array<Role>;
  access?: Access[];
  searchResult?: Role[];
}

export interface Values {
  isLoading?: PageField;
  selectedRole?: PageField;
  searchField?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {},
  {
    isLoading: createPageField(true, { required: true }),
    selectedRole: createPageField("", { required: true }),
    searchField: createPageField("", { required: true }),
  }
);

export { PageContextProvider, usePageContext };
