import { useCallback } from "react";
import { Location } from "../../pages/settings/location/list/context";
import { Session } from "../session/session-hook.types";
import config from "../../config";

const useLocation = () => {
  const fetchLocations = useCallback(async (session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(`${config.api.url}/v2/location`, {
      method: "GET",
      headers: requestHeaders,
    });
    const data = await res.json();
    return data;
  }, []);

  const addItem = useCallback(async (location: Location, session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

    const res = await fetch(`${config.api.url}/v2/location`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        description: location.description,
        parentId: location.parentId,
        status: location.status,
        view: location.view,
      }),
    });
    return res;
  }, []);

  const saveItem = useCallback(
    async (location: Location, session?: Session) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

      const res = await fetch(`${config.api.url}/v2/location/${location.id}`, {
        method: "PUT",
        headers: requestHeaders,
        body: JSON.stringify({
          description: location.description,
          parentId: location.parentId,
          status: location.status,
          view: location.view,
        }),
      });
      return res;
    },
    []
  );

  const deleteItem = useCallback(
    async (location: Location, session?: Session) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

      const res = await fetch(`${config.api.url}/v2/location/${location.id}`, {
        method: "DELETE",
        headers: requestHeaders,
      });

      return res;
    },
    []
  );

  return {
    fetchLocations,
    addItem,
    saveItem,
    deleteItem,
  };
};

export { useLocation };
