import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  Category as CategoryIcon,
  People as PeopleIcon,
  PinDrop as PinDropIcon,
} from "@mui/icons-material";
import { Grid as MuiGrid } from "@mui/material";
import Page from "../../components/page";
import SettingsMenuItem from "./components/menu-item";

function SettingsPage() {
  return (
    <Page
      title="Configurações"
      showAppBar
      showSideBar
      requiresAuthentication
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
      ]}
    >
      <MuiGrid container spacing={2}>
        <SettingsMenuItem
          icon={<PinDropIcon />}
          label={"Locais"}
          subtitle={"  Locais onde serão cadastrados os ativos da empresa"}
          url={"/settings/location"}
        />
        <SettingsMenuItem
          icon={<AdminPanelSettingsIcon />}
          label={"Perfis"}
          subtitle={"Gestão dos perfis de controle de acesso do sistema"}
          url={"/settings/roles"}
        />
        <SettingsMenuItem
          icon={<PeopleIcon />}
          label={"Usuários"}
          subtitle={"Gestão dos usuários cadastrados"}
          url={"/settings/users"}
        />
        <SettingsMenuItem
          icon={<CategoryIcon />}
          label={"Gêneros"}
          subtitle={"Tabela com os gêneros fiscais que impactam nos cálculos"}
          url={"/settings/genres"}
        />
      </MuiGrid>
    </Page>
  );
}

export default SettingsPage;
