import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import { LinkProps } from "./link.types";

function Link(props: LinkProps) {
  const muiLinkProps: MuiLinkProps = {
    variant: "body1",
  };

  if (props.href) muiLinkProps.href = props.href;

  if (props.variant) muiLinkProps.variant = props.variant;

  if (props.underline) muiLinkProps.underline = props.underline;

  return <MuiLink {...muiLinkProps}>{props.children}</MuiLink>;
}

export default Link;
