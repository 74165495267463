import { createContext, useContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import jwt from "jwt-decode";
import config from "../../config";
import { Session, SignInProps } from "./session-hook.types";

const SessionContext = createContext<any>({});

const useSession = () => {
  const navigate = useNavigate();
  const [state, setState] = useContext(SessionContext);

  const session: Session = state;

  const setSession = useCallback(
    (
      props: Session = {
        isAuthenticated: false,
      }
    ) => {
      setState((current: any) => ({ ...current, ...props }));
    },
    []
  );

  const signIn = useCallback(async (props: SignInProps) => {
    const { email, password } = props;
    if (!email || !password) throw new Error("TODO");
    const res = await fetch(`${config.api.url}/v2/auth`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    const initialData = await res.json();
    let initialToken;
    if (initialData.Token) {
      initialToken = initialData.Token;
    }

    const tokenInfo: any = jwt(initialToken);

    setSession({
      isAuthenticated: true,
      token: initialToken,
      expiresAt: tokenInfo.ExpiresAt,
      iat: tokenInfo.iat,
      account: {
        id: tokenInfo.CompanyId,
        user: {
          id: tokenInfo.UserId,
          email: email,
          name: tokenInfo.UserName,
        },
      },
    });

    return {
      isAuthenticated: true,
      token: initialToken,
      iat: tokenInfo.iat,
      expiresAt: tokenInfo.ExpiresAt,
      account: {
        id: tokenInfo.CompanyId,
        user: {
          id: tokenInfo.UserId,
          email: tokenInfo.UserEmail,
          name: tokenInfo.UserName,
        },
      },
    };
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem("token");
    setSession({
      isAuthenticated: false,
      token: null,
      iat: null,
      expiresAt: null,
      account: {
        id: null,
        user: {
          id: null,
          name: "",
          email: "",
        },
        selectedCompany: {
          id: null,
          name: "",
        },
      },
    });
    navigate("/signin");
  }, []);

  const selectCompany = useCallback(async (companyId: string) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(`${config.api.url}/v2/auth/company/${companyId}`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({}),
    });
    const data = await res.json();
    let token;
    if (data.refreshToken) {
      token = data.refreshToken;
    }
    const tokenInfo: any = jwt(token);
    setSession({
      isAuthenticated: true,
      token,
      iat: tokenInfo.iat,
      expiresAt: tokenInfo.ExpiresAt,
      account: {
        id: tokenInfo.CompanyId,
        user: {
          id: tokenInfo.UserId,
          email: tokenInfo.UserEmail,
          name: tokenInfo.UserName,
        },
        selectedCompany: {
          id: tokenInfo.CompanyId,
          name: tokenInfo.CompanyName,
        },
      },
    });

    localStorage.setItem("token", token);

    navigate("/");
  }, []);

  const sendForgotPasswordEmail = useCallback(
    async (signInProps: SignInProps) => {
      const { email } = signInProps;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");

      const res = await fetch(
        `${config.api.url}/v2/user/send-email-change-password`,
        {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify({
            email,
          }),
        }
      );
      return res;
    },
    []
  );

  return {
    ...session,
    setSession,
    signIn,
    signOut,
    selectCompany,
    sendForgotPasswordEmail,
  };
};

const SessionContextProvider = ({ children }: { children: JSX.Element }) => {
  let sessionFromCookie = null;
  const tokenFromCookie = localStorage.getItem("token");
  if (tokenFromCookie) {
    const tokenInfo: any = jwt(tokenFromCookie);
    sessionFromCookie = {
      isAuthenticated: true,
      token: tokenFromCookie,
      iat: tokenInfo.iat,
      expiresAt: tokenInfo.ExpiresAt,
      account: {
        id: tokenInfo.CompanyId,
        user: {
          id: tokenInfo.UserId,
          email: tokenInfo.UserEmail,
          name: tokenInfo.UserName,
        },
        selectedCompany: {
          id: tokenInfo.CompanyId,
          name: tokenInfo.CompanyName,
        },
      },
    };
  }

  const [state, setState] = useState(
    sessionFromCookie || {
      token: null,
      iat: null,
      expiresAt: null,
      account: {
        id: null,
        user: {
          id: null,
          name: "",
          email: "",
        },
        selectedCompany: {
          id: null,
          name: "",
        },
      },
    }
  );
  return (
    <SessionContext.Provider value={[state, setState]}>
      {children}
    </SessionContext.Provider>
  );
};

export { useSession, SessionContextProvider };
