import Button from "../../components/button";
import Typography from "../../components/typography";
import TextField from "../../components/text-field";
import Page from "../../components/page";
import {
  Check as CheckIcon,
  PendingOutlined as PendingIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { usePageContext } from "./context";
import { useSession } from "../../hooks/session";
import { useUser } from "../../hooks/user";
import { useTheme } from "@mui/material/styles";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Grid from "../../components/grid";
import { Paper } from "@mui/material";

function ChangePassword() {
  const theme = useTheme();
  const page = usePageContext();
  const hooks = { user: useUser(), session: useSession() };
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const requestedByUserAccount = queryParams.get("rbu");
  const token = queryParams.get("passwordtoken");

  const changePassword = async () => {
    await hooks.user
      .changePassword(
        token,
        page.values.password.value,
        page.values.oldPassword.value,
        !requestedByUserAccount
      )
      .then((res) => {
        let error;
        if (res.errors) {
          error = {
            hasError: true,
            errorMessage: res.errors[0].message,
          };
        }

        if (error) return page.notify("error", error.errorMessage);

        page.notify("success", "Senha alterada com sucesso!", () => {
          if (hooks.session.isAuthenticated) return hooks.session.signOut();

          navigate("/signin");
        });
      })
      .catch((err) => page.notify("error", err));
  };

  return (
    <Page context={page.context} maxWidth="md">
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <div></div> */}
        <Paper sx={{ p: 3 }}>
          <Grid container columns={2} spacing={2}>
            <Grid item xs={2}>
              <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
                Redefinir senha
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1}>
              <form
                id="sendInviteForm"
                style={{
                  width: "100%",
                  marginTop: theme.spacing(1),
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 16,
                }}
                onSubmit={async (e) => {
                  e.preventDefault();
                  let isNotValidated = page.data.rules.find(
                    (rule) => rule.status !== "ok"
                  );
                  if (isNotValidated) {
                    page.notify(
                      "error",
                      "Senha não atende aos requisitos, tente novamente"
                    );
                  } else {
                    await changePassword();
                  }
                }}
              >
                {requestedByUserAccount && (
                  <TextField
                    id="old-password"
                    name="old-password"
                    type="password"
                    fullWidth
                    margin="none"
                    label="Senha atual"
                    field={page.values.oldPassword}
                    onChange={(e) => {
                      page.setValues({
                        oldPassword: {
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                )}
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  fullWidth
                  margin="none"
                  label="Senha"
                  field={page.values.password}
                  onChange={(e) => {
                    page.setValues({
                      password: {
                        value: e.target.value,
                      },
                    });

                    page.setData({
                      rules: page.data.rules.map((rule) => {
                        if (rule.name === "number") {
                          const regexNumber = /[0-9]/;
                          if (regexNumber.test(e.target.value)) {
                            rule.status = "ok";
                          } else if (e.target.value.length === 0) {
                            rule.status = "none";
                          } else {
                            rule.status = "error";
                          }
                        }

                        if (rule.name === "characters") {
                          if (e.target.value.length >= 8) {
                            rule.status = "ok";
                          } else if (e.target.value.length === 0) {
                            rule.status = "none";
                          } else {
                            rule.status = "error";
                          }
                        }

                        if (rule.name === "upperLetter") {
                          const regexUpperCase = /[A-Z]/;
                          if (regexUpperCase.test(e.target.value)) {
                            rule.status = "ok";
                          } else if (e.target.value.length === 0) {
                            rule.status = "none";
                          } else {
                            rule.status = "error";
                          }
                        }

                        if (rule.name === "confirmPassword") {
                          if (
                            page.values.confirmPassword.value ===
                              e.target.value &&
                            e.target.value !== ""
                          ) {
                            rule.status = "ok";
                          } else if (e.target.value.length === 0) {
                            rule.status = "none";
                          } else {
                            rule.status = "error";
                          }
                        }

                        return rule;
                      }),
                    });
                  }}
                />
                <TextField
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  fullWidth
                  margin="none"
                  label="Confirmar senha"
                  field={page.values.confirmPassword}
                  onChange={(e) => {
                    page.setValues({
                      confirmPassword: {
                        value: e.target.value,
                      },
                    });

                    page.setData({
                      rules: page.data.rules.map((rule) => {
                        if (rule.name === "confirmPassword") {
                          if (
                            page.values.password.value === e.target.value &&
                            e.target.value !== ""
                          ) {
                            rule.status = "ok";
                          } else if (e.target.value.length === 0) {
                            rule.status = "none";
                          } else {
                            rule.status = "error";
                          }
                        }

                        return rule;
                      }),
                    });
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    margin: theme.spacing(2, 0, 8),
                  }}
                >
                  Alterar minha senha
                </Button>
              </form>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h6" sx={{ width: "65%" }}>
                Sua senha deve conter:
              </Typography>
              {page.data.rules.some((rule) => rule) &&
                page.data.rules.map((rule) => (
                  <div
                    key={rule.name}
                    style={{
                      display: "flex",
                      width: "65%",
                      gap: 8,
                    }}
                  >
                    <Typography component="p" variant="caption">
                      {rule.description}
                    </Typography>
                    {rule.status === "none" ? (
                      <PendingIcon fontSize="small" />
                    ) : rule.status === "ok" ? (
                      <CheckIcon fontSize="small" color="success" />
                    ) : (
                      <CloseIcon fontSize="small" color="error" />
                    )}
                  </div>
                ))}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Page>
  );
}

export default ChangePassword;
