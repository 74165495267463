import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";
import { Location } from "../../settings/users/context";

export interface ChangeWarning {
  field?: string;
  oldValue?: string | Location | number;
  newValue?: string | Location | number;
}
export interface AssetData {
  id?: string;
  description?: string;
  location?: Location;
  rfid?: string;
  number?: string;
  acquireAmount?: string;
  yearsToDepreciate?: number;
  futureSellValue?: string;
  invoiceNumber?: string;
  invoiceDate?: Date;
  observation?: string;
  genreId?: number;
}

export interface GenreValue {
  id: number;
  description?: string;
  percentage?: number;
}
export interface Data {
  locals?: Location[];
  asset?: AssetData;
  isLoading?: boolean;
  hasChange?: boolean;
  movement?: ChangeWarning;
  ocurrence?: ChangeWarning[];
  genres?: GenreValue[];
  dialog?: {
    isOpen: boolean;
  };
}

export interface Values {
  description?: PageField;
  local?: PageField;
  rfid?: PageField;
  number?: PageField;
  acquireAmount?: PageField;
  yearsToDepreciate?: PageField;
  futureSellValue?: PageField;
  invoiceNumber?: PageField;
  invoiceDate?: PageField;
  observation?: PageField;
  startDate?: PageField;
  selectedGenreId?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    dialog: { isOpen: false },
    ocurrence: [],
  },
  {
    description: createPageField("", { required: true }),
    local: createPageField("", { required: true }),
    rfid: createPageField("", { required: false }),
    number: createPageField("", { required: true }),
    acquireAmount: createPageField(undefined, { required: true }),
    yearsToDepreciate: createPageField("", { required: true }),
    futureSellValue: createPageField("", { required: true }),
    invoiceNumber: createPageField("", { required: true }),
    invoiceDate: createPageField("", { required: true }),
    observation: createPageField("", { required: false }),
    startDate: createPageField("", { required: false }),
    selectedGenreId: createPageField(undefined, { required: true }),
  }
);

export { PageContextProvider, usePageContext };
