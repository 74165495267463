import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { TextFieldProps } from "./text-field.types";

function TextField(props: TextFieldProps) {
  const muiTextFieldProps: MuiTextFieldProps = {
    fullWidth: false,
    value: "",
    margin: "none",
  };

  if (props.id) {
    muiTextFieldProps.id = props.id;
  }
  if (props.name) muiTextFieldProps.name = props.name;
  if (props.autoComplete) muiTextFieldProps.autoComplete = props.autoComplete;
  if (props.type) muiTextFieldProps.type = props.type;

  if (props.fullWidth !== undefined)
    muiTextFieldProps.fullWidth = props.fullWidth;

  if (props.field?.value) muiTextFieldProps.value = props.field.value;

  if (props.field?.required) muiTextFieldProps.required = props.field.required;

  const hasError =
    props.field?.error === true || props.field?.errorMessage !== "";
  if (hasError === true) muiTextFieldProps.error = props.field?.error;
  if (props.field?.errorMessage !== "")
    muiTextFieldProps.helperText = props.field?.errorMessage;

  if (props.onChange) muiTextFieldProps.onChange = props.onChange;

  if (props.label) muiTextFieldProps.label = props.label;

  if (props.placeholder) muiTextFieldProps.placeholder = props.placeholder;

  if (props.margin) muiTextFieldProps.margin = props.margin;

  if (props.sx) muiTextFieldProps.sx = props.sx;

  if (props.InputProps) muiTextFieldProps.InputProps = props.InputProps;

  if (props.InputLabelProps)
    muiTextFieldProps.InputLabelProps = props.InputLabelProps;

  if (props.size) muiTextFieldProps.size = props.size;

  if (props.variant) muiTextFieldProps.variant = props.variant;

  if (props.disabled) muiTextFieldProps.disabled = props.disabled;

  if (props.focused) muiTextFieldProps.focused = props.focused;

  if (props.multiline) muiTextFieldProps.multiline = props.multiline;

  if (props.rows) muiTextFieldProps.rows = props.rows;

  return <MuiTextField {...muiTextFieldProps} />;
}

export default TextField;
