import { useCallback } from "react";
import config from "../../config";
import { Session } from "../../hooks/session/session-hook.types";

const useCompany = () => {
  const fetchCompanies = useCallback(async (session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(
      `${
        config.api.url
      }/v2/company?companyUser=true&acceptInvitation=true&userId=${
        session?.account?.user?.id || ""
      }`,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );
    const data = await res.json();
    return data;
  }, []);

  return {
    fetchCompanies,
  };
};

export { useCompany };
