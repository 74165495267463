import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from "@mui/material";
import { AvatarProps } from "./avatar.types";

function Avatar(props: AvatarProps) {
  const muiAvatarProps: MuiAvatarProps = {};

  if (props.sx && props.size) {
    muiAvatarProps.sx = {
      ...props.sx,
      height: props.size,
      width: props.size,
    };
  } else if (props.size) {
    muiAvatarProps.sx = {
      height: props.size,
      width: props.size,
    };
  } else
    muiAvatarProps.sx = {
      ...props.sx,
    };

  return <MuiAvatar {...muiAvatarProps}>{props.children}</MuiAvatar>;
}

export default Avatar;
