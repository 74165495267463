import config from "../../config";
import { useCallback } from "react";
import { Invite } from "../../pages/settings/users/context";
import { Session } from "../session/session-hook.types";

const useInvite = () => {
  const fetchInvite = useCallback(async (invite: Invite, session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

    const res = await fetch(`${config.api.url}/v2/company/invite-user`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        email: invite.email,
        role: invite.roles,
        location: invite.locations,
      }),
    });
    const data = res.json();
    return data;
  }, []);

  return {
    fetchInvite,
  };
};

export { useInvite };
